import React from 'react';
import PrintIcon from '@material-ui/icons/Print';
import GetAppIcon from '@material-ui/icons/GetApp';
import { InformacaoAccordion } from '../resumo/informacaoAccordion';
import styled from '@emotion/styled';
import Chip from '@material-ui/core/Chip';
import List from '../../../../components/list/list';
import { PersonAddIcon } from '../../../../components/icons/PersonAdd';
import { PersonCallIcon } from '../../../../components/icons/PersonCall';
import { MoneyIcon, PeopleIcon } from '../../../../components/icons';
import { PeopleTeamIcon } from '../../../../components/icons/PeopleTeam';
import { DismissCircleIcon } from '../../../../components/icons/DismissCircle';
import { BoxSearchIcon } from '../../../../components/icons/BoxSearch';
import { UnidadeIcon } from '../../../../components/icons/UnidadeIcon';
import { LockOpenIcon } from '../../../../components/icons/LockOpen';
import { GiftIcon } from '../../../../components/icons/Gift';
import { LockIcon } from '../../../../components/icons/Lock';
import { AddSquareIcon } from '../../../../components/icons/AddSquare';
import colors from '../../../../theme/Colors';
import { CheckIcon } from '../../../../components/icons/CheckIcon';

export const notificationDefault = {
  variant: '',
  message: '',
  isOpen: false,
};

export const titles = [
  'Criar Venda',
  'Dados do responsável',
  'Dados empresariais',
  'Endereço',
  'Especialidade',
  'Adicionar usuários',
  'Módulos',
  'Resumo',
  'Pagamento',
];

export const profissionaisQuantidadeDefault = [
  {
    id: 3,
    label: 'Administrador(a)',
    informacao:
      'Semelhante ao secretário mas com acesso a dados financeiros. Não é gerada cobrança para novos administradores.',
    quantidade: 0,
  },
  {
    id: 1,
    label: 'Profissional de saúde',
    informacao:
      'São médicos, fisioterapeutas entre outros. Cada profissional de saúde possui uma agenda e portanto é cobrada uma licença.',
    quantidade: 0,
  },
  {
    id: 2,
    label: 'Secretário(a)',
    informacao:
      'Os secretários não possuem agenda ou possibilidade de atendimento. Não é gerada cobrança para novos secretários.',
    quantidade: 0,
  },
  {
    id: 4,
    label: 'Procedimento',
    informacao:
      'Profissional responsável pela realização de procedimentos. Deve ser vinculado a sala para possuir uma agenda. A cobrança é diferenciada.',
    quantidade: 0,
  },
];

export const tiposDesconto = [
  {
    name: 'Valor',
    value: 'VALOR',
  },
  {
    name: 'Percentil',
    value: 'PERCENTIL',
  },
];

export const tabsPagamento = [
  {
    name: 'Cartão',
    value: 'CARTAO',
  },
  {
    name: 'Boleto',
    value: 'BOLETO',
  },
];

export const opcoesParcela = [
  { value: 12, name: '1 Ano' },
  { value: 24, name: '2 Anos' },
  { value: 36, name: '3 Anos' },
  { value: 48, name: '4 Anos' },
  { value: 60, name: '5 Anos' },
];

export const getButtons = ({ functions }) => [
  {
    label: 'Imprimir',
    onClick: functions.handlePrint,
    icon: <PrintIcon />,
  },
  {
    label: 'Baixar',
    onClick: functions.handleDownload,
    icon: <GetAppIcon />,
  },
];

export const resumoVenda = ({ dados }) => {
  const {
    responsavelNome,
    paisAtuacao,
    responsavelCargo,
    razaoSocial,
    nomeFantasia,
    inscricaoEstadual,
    codigoCnes,
    email,
    endereco,
    especialidades,
    cnpj,
    telefone,
    responsavelDocumento,
    modulos,
  } = dados;

  const {
    cep,
    tipoLogradouro,
    nomeLogradouro,
    numero,
    bairro,
    municipio,
    complemento,
  } = endereco || {};

  const modulosSelecionados = modulos.filter((modulo) => modulo.selected);

  return [
    {
      title: 'Dados do responsável',
      panel: 'dadosResponsavel',
      step: 1,
      children: (
        <Content>
          <Row>
            <InformacaoAccordion
              title={'País'}
              value={paisAtuacao?.descricao}
            />
            <InformacaoAccordion
              title={'Responsável'}
              value={responsavelNome}
            />
          </Row>
          <Row>
            <InformacaoAccordion
              title={'Documento'}
              value={responsavelDocumento}
            />
            <InformacaoAccordion title={'Cargo'} value={responsavelCargo} />
          </Row>
        </Content>
      ),
    },
    {
      title: 'Dados empresariais',
      panel: 'dadosEmpresariais',
      step: 2,
      children: (
        <Content>
          <Row>
            <InformacaoAccordion title={'Razão social'} value={razaoSocial} />
            <InformacaoAccordion title={'Nome fantasia'} value={nomeFantasia} />
          </Row>
          <Row>
            <InformacaoAccordion
              title={'Inscrição estadual'}
              value={inscricaoEstadual}
            />
            <InformacaoAccordion title={'CNPJ'} value={cnpj} />
          </Row>
          <Row>
            <InformacaoAccordion title={'Telefone'} value={telefone} />
            <InformacaoAccordion title={'Código CNES'} value={codigoCnes} />
          </Row>
          <InformacaoAccordion title={'Email'} value={email} />
        </Content>
      ),
    },
    {
      title: 'Endereço',
      panel: 'endereco',
      step: 3,
      children: (
        <Content>
          <Row>
            <InformacaoAccordion title={'CEP'} value={cep} />
            <InformacaoAccordion title={'Número'} value={numero} />
          </Row>
          <Row>
            <InformacaoAccordion
              title={'Tipo Logradouro'}
              value={tipoLogradouro?.label}
            />
            <InformacaoAccordion title={'Logradouro'} value={nomeLogradouro} />
          </Row>
          <Row>
            <InformacaoAccordion title={'Bairro'} value={bairro} />
            <InformacaoAccordion title={'Complemento'} value={complemento} />
          </Row>
          <InformacaoAccordion title={'Município'} value={municipio?.label} />
        </Content>
      ),
    },
    {
      title: 'Especialidades',
      panel: 'especialidades',
      step: 4,
      children: (
        <ContentEspecialidade>
          {especialidades &&
            especialidades.map((especialidade) => (
              <ItemSelecionado
                key={especialidade.value}
                label={especialidade.label}
              />
            ))}
        </ContentEspecialidade>
      ),
    },
    {
      title: 'Profissionais',
      panel: 'profissionais',
      step: 5,
      children: (
        <Content>
          <List
            columns={[
              {
                title: 'Tipo',
                value: (item) => item.label,
              },
              {
                title: 'Quantidade',
                value: (item) => item.quantidade,
              },
            ]}
            items={dados?.profissionaisQuantidade}
            loading={false}
            last={true}
          />
        </Content>
      ),
    },
    {
      title: 'Módulos',
      panel: 'modulos',
      step: 6,
      children: (
        <ContentEspecialidade>
          {modulosSelecionados &&
            modulosSelecionados.map((modulo) => (
              <ItemSelecionado key={modulo.id} label={modulo.funcionalidade} />
            ))}
        </ContentEspecialidade>
      ),
    },
  ];
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`;

const ContentEspecialidade = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const ItemSelecionado = styled(Chip)`
  margin: 1px 2px !important;
`;

export const buttonsDetalheVenda = ({
  functions,
  showButtonAdicionarProfissionalSaude,
  isAuthoritySistema,
  isStatusFinalizada,
  showButtonIniciarTesteGratis,
  validaExtenderPeriodoGratis,
  unidadeVencida,
  canEdit,
  useSecretaria,
  tipoSecretariaVirtual,
}) => [
    {
      title: 'Novo profissional',
      icon: <PersonAddIcon />,
      onClick: functions.handleClickCriarProfissionalSaude,
      hidden: !showButtonAdicionarProfissionalSaude,
    },
    {
      title: `Secr. virtual - ${tipoSecretariaVirtual === "PLUGZAP" ? "Plugzapi" : "Oficial"}`,
      icon: <PersonCallIcon />,
      onClick: functions.toggleSecretariaVirtualDialog,
      switchChecked: useSecretaria,
      isSwitch: true,
      hidden: !canEdit || !tipoSecretariaVirtual,
    },
    {
      title: showButtonIniciarTesteGratis
        ? 'Iniciar teste grátis'
        : 'Estender teste grátis',
      icon: showButtonIniciarTesteGratis ? <GiftIcon /> : <AddSquareIcon />,
      onClick: functions.toggleEstenderPeriodoGratisDialog,
      hidden: !showButtonIniciarTesteGratis && !validaExtenderPeriodoGratis,
    },
    {
      title: unidadeVencida ? 'Desbloquear venda' : 'Bloquear venda',
      icon: unidadeVencida ? <LockOpenIcon /> : <LockIcon />,
      onClick: unidadeVencida
        ? functions.handleOpenDesbloquearVendaDialog
        : functions.handleOpenBloquearVendaDialog,
      hidden: !isAuthoritySistema,
    },
    {
      title: 'Sobre o plano',
      icon: <BoxSearchIcon />,
      onClick: functions.handleClickSobrePlano,
      hidden: !canEdit,
    },
    {
      title: 'Financeiro',
      icon: <MoneyIcon />,
      onClick: functions.handleClickMovimentacoes,
    },
    {
      title: 'Grupos de permissão',
      icon: <PeopleTeamIcon />,
      onClick: functions.handleClickEditarGrupoPermissao,
      hidden: !isAuthoritySistema,
    },
    {
      title: 'Dados da unidade',
      icon: <UnidadeIcon />,
      onClick: functions.handleClickEditarDadosUnidade,
      hidden: !canEdit,
    },
    {
      title: 'Cancelar venda',
      icon: <DismissCircleIcon />,
      onClick: functions.handleOpenFinalizarVenda,
      bg: '#FB7676',
      color: '#fff',
      hidden: isStatusFinalizada,
    },
    {
      title: 'Ativar venda',
      icon: <CheckIcon />,
      onClick: functions.handleOpenAtivarVenda,
      bg: '#219A97',
      color: '#fff',
      hidden: !(isAuthoritySistema && isStatusFinalizada),
    },
    {
      title: 'Perfil Público',
      icon: <PeopleIcon />,
      onClick: functions.handleOpenPerfilPublico,
      bg: colors.primary.dark,
      color: '#fff',
      hidden: !canEdit,
    },
  ];
