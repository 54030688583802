import React from 'react';
import moment from 'moment';
import Number from '../../utils/number';
import styled from '@emotion/styled';
import { FieldDestaque } from './components/field';
import { cpfCnpjFormatted } from '../../utils/masks';
import Switch from '@material-ui/core/Switch';
import GeraSenhaButton from './components/gera-senha-button';
import string from '../../utils/string';
import Button from '../../components/button';

import { Link } from 'react-router-dom';
import { PERFIL_PUBLICO_PROFISSIONAL_VENDA } from '../../router/names';

export const dadosVendaCard = ({ dados, functions , canEdit }) => {
  const {
    plano,
    dataLancamento,
    valorPorProfissionalComDesconto,
    diasPeriodoGratis,
    unidade,
    valorTotalComDesconto,
    valorUnico,
    clientePagamentoResponse,
    meioPagamentoPlataforma,
  } = dados;

  return [
    {
      title: 'Plano',
      value: plano.nome,
    },
    {
      title: 'Lançamento',
      value: moment(dataLancamento).format('DD/MM/YYYY'),
    },
    {
      title: 'Valor por profissional',
      value: Number.currencyFormat(valorPorProfissionalComDesconto),
    },
    {
      title: 'Pagamento',
      value: string.capitalize(meioPagamentoPlataforma || 'Não definido'),
    },
    {
      title: 'Id da unidade',
      value: unidade?.id,
    },
    {
      title: 'Período grátis',
      value: diasPeriodoGratis,
    },
    {
      title: 'Data de vencimento',
      value: moment(unidade.dataVencimento).format('DD/MM/YYYY'),
      canEdit: canEdit,
      handleEdit: functions.handleOpenModalDataVencimento
    },
    {
      title: 'Valor recorrente',
      value: Number.currencyFormat(valorTotalComDesconto),
      canEdit: canEdit,
      handleEdit: functions.handleOpenModalEditValorRecorrente
    },
    {
      title: 'Valor adicionais único',
      value: Number.currencyFormat(valorUnico),
    },
    {
      title: 'Valor cielo',
      value: Number.currencyFormat(clientePagamentoResponse?.valor),
    },
  ];
};

export const dadosVendedorCard = ({ dados }) => {
  const { vendedor } = dados;
  const { nome, documento, email } = vendedor;

  return [
    {
      title: 'Nome',
      value: nome,
    },
    {
      title: 'Documento',
      value: documento,
    },
    {
      title: 'E-mail',
      value: email,
    },
  ];
};

export const dadosResponsavelCard = ({ dados }) => {
  const { contratante } = dados;
  const {
    responsavelNome,
    responsavelCargo,
    responsavelDocumento,
    paisAtuacao,
    cnpj,
  } = contratante;

  return [
    {
      title: 'País*',
      value: paisAtuacao?.descricao,
    },
    {
      title: 'CNPJ',
      value: cnpj,
    },
    {
      title: 'Nome do responsável',
      value: responsavelNome,
    },
    {
      title: 'Documento',
      value: responsavelDocumento,
    },
    {
      title: 'Cargo',
      value: responsavelCargo,
    },
  ];
};

export const dadosEmpresariaisCard = ({ dados }) => {
  const { contratante } = dados;
  const {
    nomeFantasia,
    razaoSocial,
    inscricaoEstadual,
    telefone,
    email,
    codigoCnes,
  } = contratante;

  return [
    {
      title: 'Razão social*',
      value: razaoSocial,
    },
    {
      title: 'Nome fantasia*',
      value: nomeFantasia,
    },
    {
      title: 'Inscrição estadual',
      value: inscricaoEstadual,
    },
    {
      title: 'Telefone*',
      value: telefone,
    },
    {
      title: 'Código CNES',
      value: codigoCnes,
    },
    {
      title: 'E-mail',
      value: email,
    },
  ];
};

export const enderecoCard = ({ dados }) => {
  const { endereco } = dados?.contratante;
  const {
    cep,
    nomeLogradouro,
    numero,
    bairro,
    complemento,
    municipio,
    tipoLogradouro,
  } = endereco || {};

  const municipioLabel = `${municipio?.label} - ${municipio?.uf || ''}`;

  return [
    {
      title: 'Cep*',
      value: cep,
    },
    {
      title: 'Número*',
      value: numero,
    },
    {
      title: 'Tipo logradouro*',
      value: tipoLogradouro?.label,
    },
    {
      title: 'Logradouro*',
      value: nomeLogradouro,
    },
    {
      title: 'Bairro*',
      value: bairro,
    },
    {
      title: 'Complemento',
      value: complemento,
    },
    {
      title: 'Município',
      value: municipioLabel,
    },
  ];
};

export const profissionaisCadastradosCard = ({
  dados,
  quantidadeTipoProfissional,
}) => {
  const profissionais = [
    {
      title: 'Profissional de saúde',
      tipo: 'Atendente',
    },
    {
      title: 'Secretário(a)',
      tipo: 'Secretário',
    },
    {
      title: 'Técnico(a)',
      tipo: 'Procedimento',
    },
    {
      title: 'Administrador(a)',
      tipo: 'Administrador',
    },
  ];

  const profissionaisQuantidade = profissionais?.map((profissional) => {
    const profissionalTipo = dados?.find(
      (dados) => dados.tipo === profissional.tipo
    );
    const quantidadeMaximoTipoProfissional = quantidadeTipoProfissional?.find(
      (item) => item.tipoProfissionalSaude.nome === profissional.tipo
    );

    const value = `${profissionalTipo?.quantidade || 0}/${
      quantidadeMaximoTipoProfissional?.quantidade || 0
    }`;

    return {
      title: profissional.title,
      value,
    };
  });

  return profissionaisQuantidade;
};

export const dadosRegistradosCard = ({ dados, venda }) => {
  const {
    quantidadeAgendamentosPassados,
    quantidadeAgendamentosFuturos,
    findTotalEntradaProntuarioByUnidadeId,
    findTotalSujeitoAtencaoByUnidadeId,
    quantidadeAgendamentosPassadosByWhitelabelLogada,
    quantidadeAgendamentosFuturosByWhitelabelLogada,
  } = dados || {};

  const whitelabel = venda?.unidade?.rede?.whitelabel?.nomeEmpresa

  return [
    {
      title: 'Agendamentos',
      children: (
        <ContentAgendamento>
          <FieldDestaque
            title="Futuros"
            value={quantidadeAgendamentosFuturos}
          />
          <FieldDestaque
            title="Últimos 5 dias"
            value={quantidadeAgendamentosPassados}
          />
        </ContentAgendamento>
      ),
    },
    {
      title: `Agendamentos - ${whitelabel || ''}`,
      children: (
        <ContentAgendamento>
          <FieldDestaque
            title="Futuros"
            value={quantidadeAgendamentosFuturosByWhitelabelLogada}
          />
          <FieldDestaque
            title="Últimos 5 dias"
            value={quantidadeAgendamentosPassadosByWhitelabelLogada}
          />
        </ContentAgendamento>
      ),
    },
    {
      title: 'Pacientes cadastrados',
      value: findTotalSujeitoAtencaoByUnidadeId,
    },
    {
      title: 'Entradas no prontuário',
      value: findTotalEntradaProntuarioByUnidadeId,
    },
  ];
};

const ContentAgendamento = styled.div`
  display: flex;
  flex-direction: row;
`;

const tiposProfissionais = {
  1: 'Médico(a)',
  2: 'Secretário(a)',
  3: 'Administrador(a)',
  4: 'Técnico(a)',
};

export const columnsProfissionaisSaude = ({ vendaId }) => [
  {
    title: 'Tipo',
    value: (item) => tiposProfissionais[item.tipo],
  },
  {
    title: 'ID',
    value: (item) => item.id,
  },
  {
    title: 'Nome',
    value: (item) => item.nome,
  },
  {
    title: 'E-mail',
    value: (item) => item.email,
  },
  {
    title: 'CPF',
    value: (item) => cpfCnpjFormatted(item.cpf),
  },
  {
    title: 'Status',
    value: (item) => <Switch checked={item.ativo} color="primary" />,
  },
  {
    title: 'Perfil Público',
    value: (item) => {
      if (!item.utilizaVIZI) return;

      return (
        <Link
          to={PERFIL_PUBLICO_PROFISSIONAL_VENDA.replace(
            ':vendaId',
            vendaId
          ).replace(':profissionalId', item.id)}
        >
          <Button>Perfil Público</Button>
        </Link>
      );
    },
  },
  {
    title: 'Senha',
    value: (item) => <GeraSenhaButton profissional={item} />,
  },
];

export const adicionaisSwitch = ({ dados, id, whitelabel }) => {
  const {
    possuiAgenda,
    utilizaAssinaturaDigital,
    utilizaTelemedicina,
    utilizaSecretariaVirtual,
    prontuarioExclusivo,
    ativo,
    utilizaVIZI,
    permiteAvaliacaoVizi,
    tipo,
  } = dados;

  return [
    {
      title: 'Médico',
      field: 'possuiAgenda',
      value: possuiAgenda,
      show: true,
      disabled: tipo?.value !== 3,
    },
    {
      title: 'Assinatura Digital',
      field: 'utilizaAssinaturaDigital',
      value: utilizaAssinaturaDigital,
      show: possuiAgenda,
    },
    {
      title: 'Telemedicina',
      field: 'utilizaTelemedicina',
      value: utilizaTelemedicina,
      show: possuiAgenda,
    },
    {
      title: 'Secretaria Virtual',
      field: 'utilizaSecretariaVirtual',
      value: utilizaSecretariaVirtual,
      show: possuiAgenda,
    },
    {
      title: 'Prontuário exclusivo',
      field: 'prontuarioExclusivo',
      value: prontuarioExclusivo,
      show: possuiAgenda,
    },
    {
      title: 'Ativo',
      field: 'ativo',
      value: ativo,
      show: id,
    },
    {
      title: whitelabel,
      field: 'utilizaVIZI',
      value: utilizaVIZI,
      show: true,
    },
    {
      title: 'Envia avaliações',
      field: 'permiteAvaliacaoVizi',
      value: permiteAvaliacaoVizi,
      show: utilizaVIZI,
    },
  ];
};

export const pageableDTOProfissionaisDefault = {
  pageNumber: 0,
  pageSize: 9999,
  sortField: 'nome',
  sortDir: 'ASC',
};
