import gql from 'graphql-tag';

export const LIST_UNITS = gql`
  query findAllUnidade($search: String) {
    findAllUnidade(searchDTO: { search: $search }) {
      content {
        id
        nome
        cnpj
      }
    }
  }
`;

export const LIST_ENTITY = gql`
  query findAllEntidade(
    $search: String
    $entidadePai: EntidadeInput
    $ativo: Boolean
    $nivel: EntidadeNivelInput
    $page: Int
    $sortField: String
  ) {
    findAllEntidade(
      searchDTO: {
        search: $search
        entidade: $entidadePai
        ativo: $ativo
        entidadeNivel: $nivel
      }
      pageableDTO: { pageNumber: $page, sortField: $sortField }
    ) {
      last
      totalPages
      size
      number
      content {
        id
        nome
        documento
        ativo
        tipo
        nivel {
          nome
        }
      }
    }
    findAllEntidadeNiveisVisiveisEntidadeLogada {
      id
      value: id
      name: nome
    }
    findAllEntidadesSimple: findAllEntidade(
      searchDTO: {}
      pageableDTO: { pageSize: 9999 }
    ) {
      content {
        id
        value: id
        name: nome
        tipo
      }
    }
  }
`;

export const LIST_MOVIMENTACAO_FINANCEIRA = gql`
  query findAllMovimentacaoFinanceira(
    $searchDTO: SearchEntidadeDTOInput
    $pageableDTO: PageableDTOInput
  ) {
    findAllMovimentacaoFinanceira(
      searchDTO: $searchDTO
      pageableDTO: $pageableDTO
    ) {
      last
      totalPages
      size
      number
      content {
        id
        dataLancamento
        valorCheio
        valorRepasse
        percentualRepasse
      }
    }
  }
`;

export const LIST_FECHAMENTO = gql`
  query findAllFechamentoFinanceiro(
    $searchDTO: SearchDTOInput
    $pageableDTO: PageableDTOInput
  ) {
    findAllFechamentoFinanceiro(
      searchDTO: $searchDTO
      pageableDTO: $pageableDTO
    ) {
      last
      totalPages
      size
      number
      content {
        id
        dataInicioPeriodo
        dataFimPeriodo
        valor
        dataLancamento
        dataPagamento
      }
    }
  }
`;

export const DROPDOWN_ENTIDADES = gql`
  query findAllEntidadeByOauthClient(
    $searchDTO: SearchEntidadeDTOInput
    $pageableDTO: PageableDTOInput
  ) {
    findAllEntidadeByOauthClient(
      searchDTO: $searchDTO
      pageableDTO: $pageableDTO
    ) {
      last
      content {
        value: id
        name: nome
      }
    }
  }
`;

export const DROPDOWN_PLANOS = gql`
  query findAllPlanoAtivo($searchDTO: SearchDTOInput) {
    findAllPlanoAtivo(searchPlanoDTO: $searchDTO) {
      value: id
      label: nome
      diasPeriodoExperiencia
    }
  }
`;

export const LIST_PLANOS = gql`
  query findAllPlanoAtivo($searchDTO: SearchDTOInput) {
    findAllPlanoAtivo(searchPlanoDTO: $searchDTO) {
      id
      nome
      diasPeriodoExperiencia
      valor
      ordem
    }
  }
`;

export const DROPDOWN_MUNICIPIOS = gql`
  query findAllMunicipio($searchDTO: SearchDTOInput) {
    findAllMunicipio(searchDTO: $searchDTO) {
      last
      content {
        value: id
        label: nome
        uf
        codigoIBGE
        estado {
          value: id
          label: nome
        }
      }
    }
  }
`;

export const DROPDOWN_ESTADOS = gql`
  query findAllEstadp($searchDTO: SearchDTOInput) {
    findAllEstado(searchDTO: $searchDTO) {
      last
      content {
        value: id
        label: nome
      }
    }
  }
`;

export const DROPDOWN_GROUP_BY_REDE_ID = gql`
  query findGroupByRedeId($idRede: Long, $ativo: Boolean) {
    findGroupByRedeId(idRede: $idRede, ativo: $ativo) {
      value: id
      label: nome
    }
  }
`;

export const LIST_VENDAS = gql`
  query findAllVenda(
    $searchDTO: SearchEntidadeDTOInput
    $pageableDTO: PageableDTOInput
  ) {
    findAllVenda(searchDTO: $searchDTO, pageableDTO: $pageableDTO) {
      last
      totalPages
      size
      number
      content {
        id
        status
        unidade {
          id
          nome
          dataVencimento
        }
        dataLancamento
        dataUltimoPagamento
        valorTotalComDesconto
        tipoSecretariaVirtual
        plano {
          nome
        }
        vendedor {
          nome
        }
        meioPagamentoPlataforma
      }
    }
  }
`;

export const FIND_BY_ID_VENDA = gql`
  query findByIdVenda($id: UUID) {
    findByIdVenda(id: $id) {
      id
      status
      dataLancamento
      dataUltimoPagamento
      dataEncerramento
      valorPorProfissionalSaude
      valorPorProfissionalComDesconto
      valor
      tipoSecretariaVirtual
      valorUnico
      valorTotalComDesconto
      diasPeriodoGratis
      utilizaAzulControle
      plano {
        id
        nome
        valor
      }
      vendaModulos {
        adicional
        id
        funcionalidade
        valor
        modulo {
          id
        }
      }
      desconto {
        valorDesconto
        tipoDesconto
      }
      vendedor {
        id
        tipo
        nome
        documento
        email
      }
      unidade {
        id
        nome
        nomeFantasia
        codigoCnes
        telefonePrincipal
        telefonePrincipalDDI
        perfilPublico
        dataVencimento
        plugzapiClientToken
        plugzapiUrl
        endereco {
          bairro
          cep
          complemento
          municipio {
            value: id
            label: nome
          }
          nomeLogradouro
          numero
          tipoLogradouro {
            descricao
            id
          }
          estado {
            id
            uf
          }
        }
        espanhaMunicipio {
          codigoMunicipio
          descricao
          id
          label: descricao
          value: id
          provincia {
            descricao
            id
            label: descricao
            value: id
            comunidadeAutonoma {
              descricao
              id
              label: descricao
              value: id
            }
          }
        }
        rede {
          id
          nome
          paisAtuacao {
            id
            descricao
            label: descricao
            value: id
          }
          unidades {
            id
          }

          whitelabel {
            id
            corPrincipal
            frontWebUrl
            nomeEmpresa
          }
        }
      }
      contratante {
        responsavelNome
        responsavelCargo
        responsavelDocumento
        nomeFantasia
        razaoSocial
        cnpj
        inscricaoEstadual
        telefone
        telefoneDDI
        codigoCnes
        email
        documentoTipo {
          descricao
          id
          label: descricao
          value: id
        }
        paisAtuacao {
          descricao
          id
          label: descricao
          value: id
        }
        endereco {
          cep
          nomeLogradouro
          numero
          bairro
          complemento
          municipio {
            value: id
            label: nome
            uf
          }
          estado {
            value: id
            label: nome
          }
          tipoLogradouro {
            value: id
            label: descricao
          }
        }
        espanhaMunicipio {
          value: id
          label: descricao
          provincia {
            value: id
            label: descricao
            comunidadeAutonoma {
              value: id
              label: descricao
            }
          }
        }
        tempoFuncionamentoEmpresa
        numeroPacientes
        haveraMigracaoSistema
        sistemaASerMigrado
        especialidades {
          value: id
          label: especialidade
        }
      }
      meiosPagamento {
        cartaoCredito {
          cardNumber
          expirationDate
          holder
        }
      }
      meioPagamentoPlataforma
      tipoPagamento {
        id
        descricao
      }
      vendaQuantidadeTipoProfissionalSaude {
        quantidade
        tipoProfissionalSaude {
          id
          nome
        }
      }
    }
  }
`;

export const LIST_BENEFICIARIOS_DA_VENDA = gql`
  query listBeneficiariosDaVenda($entidade: EntidadeInput) {
    findAllEntidadesEntreUsuarioLogadoEEntidade(entidade: $entidade) {
      id
      nivel {
        nome
      }
      nome
      documento
    }
  }
`;

export const LIST_MOVIMENTACOES_FINANCEIRAS_DA_VENDA = gql`
  query listMovimentacoesFinanceirasDaVenda($venda: VendaInput) {
    findAllMovimentacoesFinanceirasEntreUsuarioLogadoEVendedor(venda: $venda) {
      id
      tipo
      dataLancamento
      valorCheio
      valorRepasse
      percentualRepasse
      beneficiario {
        nome
      }
    }
  }
`;

export const LIST_ENTITY_SIMPLE = gql`
  query findAllEntidadeSimple {
    findAllEntidade(searchDTO: {}, pageableDTO: { pageSize: 9999 }) {
      content {
        id
        nome
        tipo
      }
    }
  }
`;

export const DROPDOWN_ENTIDADES_PAI = gql`
  query DropdownEntidadesPai {
    findAllEntidade(searchDTO: {}, pageableDTO: { pageSize: 9999 }) {
      content {
        id
        value: id
        name: nome
        tipo
      }
    }
  }
`;

export const DROPDOWN_NIVEIS_ENTIDADE_LOGADA = gql`
  query DropdownNiveisEntidadeLogada {
    findAllEntidadeNiveisVisiveisEntidadeLogada {
      id
      value: id
      name: nome
    }
  }
`;

export const LIST_LEVELS = gql`
  query findAllEntidadeNiveisVisiveisEntidadeLogada {
    findAllEntidadeNiveisVisiveisEntidadeLogada {
      id
      nome
    }
  }
`;

export const LIST_BANK_SIMPLE = gql`
  query {
    findAllBanco(pageableDTO: { pageSize: 9999 }) {
      content {
        id
        nome
        value: id
        codigo
        name: nome
      }
    }
  }
`;

export const LIST_BANK_BUSCA = gql`
  query ($searchDTO: SearchDTOInput, $pageableDTO: PageableDTOInput) {
    bancos: findAllBanco(searchDTO: $searchDTO, pageableDTO: $pageableDTO) {
      content {
        id
        nome
        value: id
        codigo
        label: nome
      }
    }
  }
`;

export const GET_ENTITY_ID = gql`
  query findByIdEntidade($id: UUID) {
    findByIdEntidade(id: $id) {
      ativo
      codigo
      documento
      email
      id
      nome
      tipo
      responsavelNome
      responsavelCpf
      usuario {
        id
      }
      dadoBancario {
        agencia
        conta
        documento
        nome
        codigoVerificador
        banco {
          id
          nome
        }
      }
    }
  }
`;

export const GET_ENTITY_FISICA_ID = gql`
  query findByIdEntidade($id: UUID) {
    findByIdEntidadeFisica(id: $id) {
      ativo
      codigo
      cpf
      email
      id
      nome
      tipo
      responsavelNome
      responsavelCpf
      usuario {
        id
        __typename
      }
      dadoBancario {
        agencia
        conta
        documento
        nome
        banco {
          id
          nome
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const GET_VENDA_CLIENTE_PAGAMENTO_ID = gql`
  query findByIdVendaClientePagamento($id: UUID, $cpf: String) {
    findByIdVendaClientePagamento(id: $id, cpf: $cpf) {
      id
      valor
      contratante {
        cnpj
        nomeFantasia
        telefone
        email
        endereco {
          nomeLogradouro
          numero
          cep
          estado {
            nome
          }
          municipio {
            nome
            uf
          }
          bairro
        }
      }
      vendedor {
        nome
        responsavelNome
      }
      plano {
        nome
        valor
      }
      unidade {
        nome
        nomeFantasia
        endereco {
          nomeLogradouro
          numero
          cep
          estado {
            nome
          }
          municipio {
            nome
          }
          bairro
        }
      }
    }
  }
`;

export const GET_ENTITY_JURIDICA_ID = gql`
  query findByIdEntidade($id: UUID) {
    findByIdEntidadeJuridica(id: $id) {
      razaoSocial
      nomeFantasia
      cnpj
      ativo
      codigo
      email
      id
      tipo
      responsavelNome
      responsavelCpf
      usuario {
        id
        __typename
      }
      dadoBancario {
        agencia
        conta
        documento
        nome
        banco {
          id
          nome
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const GET_ENTITY_BY_CODE = gql`
  query findEntidadeNomeByCodigo($codigo: String!) {
    findEntidadeNomeByCodigo(codigo: $codigo)
  }
`;

export const GET_ENTITY_NIVEL_ROLE_BY_CODE = gql`
  query ($codigo: String!) {
    findEntidadeNivelAuthorityByCodigo(codigo: $codigo)
  }
`;

export const LIST_ESPECIALIDADES_MEDICAS = gql`
  query ($search: SearchDTOInput, $pageableDTO: PageableDTOInput) {
    findAllEspecialidadeMedica(searchDTO: $search, pageableDTO: $pageableDTO) {
      content {
        label: especialidade
        value: id
      }
    }
  }
`;

export const CONSULTA_CNPJ = gql`
  query ($cnpj: String) {
    consultaDeCnpj(cnpj: $cnpj) {
      cep
      nomeFantasia
      numeroEndereco
      razaoSocial
    }
  }
`;

export const CONSULTA_CEP = gql`
  query ($cep: String) {
    consultaDeCep(cep: $cep) {
      cep
      bairro
      cidade {
        value: id
        label: nome
        codigoIbge
      }
      municipio {
        value: id
        label: nome
        id
        nome
        uf
        estado {
          value: id
          label: nome
          id
          nome
        }
      }
      logradouro
      tipoLogradouro {
        label: tipo
        value: id
      }
    }
  }
`;

export const POSSUI_VENDAS_COM_OUTRO_VENDEDOR = gql`
  query ($cnpj: String) {
    possuiVendasComOutroVendedor(cnpj: $cnpj)
  }
`;

export const DROPDOWN_TIPO_LOGRADOURO = gql`
  query findAllTipoLogradouro($search: SearchDTOInput) {
    findAllTipoLogradouro(searchDTO: $search) {
      last
      content {
        value: id
        label: descricao
      }
    }
  }
`;

export const DROPDOWN_UF = gql`
  {
    findAllEstadoList {
      value: uf
      label: uf
    }
  }
`;

export const QUANTIDADE_AGENDAMENTOS_PASSADOS = gql`
  query ($unidade: UnidadeInput) {
    quantidadeAgendamentosPassados(unidade: $unidade)
  }
`;

export const QUANTIDADE_AGENDAMENTOS_FUTUROS = gql`
  query ($unidade: UnidadeInput) {
    quantidadeAgendamentosFuturos(unidade: $unidade)
  }
`;

export const GET_DADOS_REGISTRADOS_PROFISSIONAIS = gql`
  query ($unidade: UnidadeInput, $unidadeId: Long, $venda: VendaInput) {
    quantidadeAgendamentosPassados(unidade: $unidade)
    quantidadeAgendamentosPassadosByWhitelabelLogada
    quantidadeAgendamentosFuturos(unidade: $unidade)
    quantidadeAgendamentosFuturosByWhitelabelLogada
    findTotalEntradaProntuarioByUnidadeId(unidadeId: $unidadeId)
    findTotalSujeitoAtencaoByUnidadeId(unidadeId: $unidadeId)
    findQuantidadeProfissionaisSaudeByVenda(venda: $venda) {
      quantidade
      tipo
    }
  }
`;

export const FIND_ALL_TIPO_PROFISSIONAL_SAUDE_LIST = gql`
  query {
    findAllTipoProfissionalSaudeList {
      value: id
      name: nome
      label: nome
      descricao
    }
  }
`;

export const LIST_GRUPO_PERMISSAO = gql`
  query ($idRede: Long, $ativo: Boolean) {
    findGroupByRedeId(idRede: $idRede, ativo: $ativo) {
      id
      nome
      ativo
    }
  }
`;

export const PROFISSIONAL_SAUDE_AUTHORITY_SECTION_LIST = gql`
  query {
    findAllProfissionalSaudeAuthoritySectionList {
      nome
      authorities {
        authority
        nome
        ordem
      }
    }
  }
`;

export const FIND_ALL_VENDA_AUDIT_BY_VENDA_ID = gql`
  query findAllVendaAuditByVendaId(
    $vendaId: UUID
    $pageableDTO: PageableDTOInput
  ) {
    findAllVendaAuditByVendaId(vendaId: $vendaId, pageableDTO: $pageableDTO) {
      content {
        vendedorModified
        valorPorProfissionalSaudeModified
        valorModified
        valorAzulControleModified
        utilizaAzulControleModified
        tokenRecorrenciaModified
        statusModified
        meioPagamentoModified
        entidadeFinalizacaoModified
        diasPeriodoGratisModified
        dataUltimoPagamentoModified
        dataLancamentoModified
        dataEncerramentoModified
        codigoClienteAzulControleModified
        vendaAuditId {
          revisao {
            id
            usuario {
              id
              nome
              fotoPerfil
            }
            dataHoraModificacao
          }
        }
        codigoClienteAzulControle
        dataEncerramento
        dataLancamento
        dataUltimoPagamento
        diasPeriodoGratis
        entidadeFinalizacao {
          nome
        }
        meioPagamentoPlataforma
        plano {
          nome
        }
        plano_mod
        status
        tokenRecorrencia
        utilizaAzulControle
        valorAzulControle
        valor
        valorPorProfissionalSaude
        vendedor {
          nome
        }
        revtype
      }
      last
      totalElements
      totalPages
    }
  }
`;

export const FIND_BY_ID_GROUP = gql`
  query findGroupById($idGroup: Long) {
    findGroupById(idGroup: $idGroup) {
      id
      nome
      ativo
      authorities {
        authority
        nome
        ordem
        section {
          nome
        }
      }
    }
  }
`;

export const FIND_ALL_PAIS = gql`
  query ($searchDTO: SearchDTOInput, $pageableDTO: PageableDTOInput) {
    findAllPais(searchDTO: $searchDTO, pageableDTO: $pageableDTO) {
      last
      content {
        id
        descricao
        value: id
        label: descricao
      }
    }
  }
`;

export const FIND_ALL_ESPANHA_COMUNIDADE_AUTONOMA = gql`
  {
    findAllEspanhaComunidadeAutonoma(
      pageableDTO: { sortField: "descricao", sortDir: "ASC" }
    ) {
      value: id
      name: descricao
    }
  }
`;

export const FIND_PROVINCIA = gql`
  query ($codigoComunidadeAutonoma: String, $pageableDTO: PageableDTOInput) {
    findAllEspanhaProvincia(
      codigoComunidadeAutonoma: $codigoComunidadeAutonoma
      pageableDTO: $pageableDTO
    ) {
      id
      descricao
      value: id
      name: descricao
    }
  }
`;

export const FIND_ESPANHA_MUNICIPIOS = gql`
  query (
    $codigoProvincia: String
    $pageableDTO: PageableDTOInput
    $descricao: String
  ) {
    findAllEspanhaMunicipio(
      codigoProvincia: $codigoProvincia
      pageableDTO: $pageableDTO
      descricao: $descricao
    ) {
      last
      content {
        id
        codigoMunicipio
        descricao
        value: id
        label: descricao
      }
    }
  }
`;

export const FIND_BRASIL = gql`
  {
    findBrasil {
      descricao
      id
      value: id
      label: descricao
    }
  }
`;

export const TIPOS_DOCUMENTO = gql`
  query ($pageableDTO: PageableDTOInput) {
    findAllDocumentoTipo(pageableDTO: $pageableDTO) {
      id
      descricao
      value: id
      name: descricao
    }
  }
`;

export const FIND_DADOS_BRASPAG_PROFISSIONAL_SAUDE = gql`
  query ($profissionalSaudeId: Long) {
    findByDadosBraspagProfissionalSaude(
      profissionalSaudeId: $profissionalSaudeId
    ) {
      id
      dadoBancario {
        agencia
        banco {
          codigo
          id
          nome
        }
        conta
        digitoAgencia
        digitoVerificador
        documento
        nome
        operacao
        tipoConta
      }
      dataNascimento
      status
      nomeFantasia
      razaoSocial
      responsavelNome
      email
      profissionalSaudeBraspagAnexo {
        amazonS3Objeto {
          extensao
          id
          nome
          tamanho
          nomeComExtensao
        }
        arquivoTipo
        urlDownload
        urlMiniatura
      }
    }
  }
`;

export const FIND_TOTAL_ENTRADA_PRONTUARIO_BY_UNIDADE_ID = gql`
  query ($unidadeId: Long) {
    findTotalEntradaProntuarioByUnidadeId(unidadeId: $unidadeId)
  }
`;

export const FIND_TOTAL_SUJEITO_ATENCAO_BY_UNIDADE_ID = gql`
  query ($unidadeId: Long) {
    findTotalSujeitoAtencaoByUnidadeId(unidadeId: $unidadeId)
  }
`;

export const FIND_BY_UNIDADE_CONFIGURACAO_UNIDADE = gql`
  query ($unidadeId: Long) {
    findByUnidadeConfiguracaoUnidade(unidadeId: $unidadeId) {
      utilizaWaba
      utilizaPlugzapi
    }
  }
`;

export const LIST_QUANTIDADE_WHATSAPP = gql`
  query (
    $search:String
    $dataFim: LocalDate
    $dataInicio: LocalDate
    $pageableDTO: PageableDTOInput
  ) {
    relatorioEnvioMensagens(
      search:$search
      dataFim: $dataFim
      dataInicio: $dataInicio
      pageableDTO: $pageableDTO
    ) {
      last
      content {
        unidade
        nomeFantasia
        quantidade
      }
    }
  }
`;

export const FIND_BY_ID_PROFISSIONAL_SAUDE_VENDA = gql`
  query ($vendaId: UUID, $id: Long) {
    findByIdProfissionalSaudeDaVenda(vendaId: $vendaId, id: $id) {
      id
      possuiAgenda
      nome
      prefixo {
        prefixo
      }
      cpf
      email
      ativo
      cns
      dataNascimento
      dataVencimento
      dataCadastro
      numeroConselho
      ufConselho
      utilizaAssinaturaDigital
      utilizaTelemedicina
      utilizaSecretariaVirtual
      perfilPublico
      prontuarioExclusivo
      utilizaVIZI
      atendePresencial
      tipo
      informacao {
        profissionalSaudeProfissao {
          id
          descricao
        }
        telefonePrincipal
        telefoneSecundario
        formasPagamento {
          id
          nome
        }
        idiomas {
          id
          descricao
        }
      }
      especialidades {
        id
        especialidade
        value: id
        label: especialidade
      }
      usuario {
        id
        email
      }
      endereco {
        nomeLogradouro
        numero
        bairro
        cep
        complemento
        municipio {
          value: id
          label: nome
        }
        estado {
          value: id
          label: nome
        }
      }
      dadoBancario {
        banco {
          value: id
          codigo
          label: nome
        }
        tipoConta
        conta
        operacao
        digitoVerificador
        agencia
        digitoAgencia
        documento
      }
      unidadeProfissionalSaudeGroups {
        group {
          value: id
          label: nome
        }
      }

      tuss24TerminologiaCBO {
        id
        termo
        codigoTermo
      }
    }
  }
`;

export const FIND_ALL_MODULO = gql`
  query ($ativo: Boolean, $pageableDTO: PageableDTOInput) {
    findAllModulo(ativo: $ativo, pageableDTO: $pageableDTO) {
      id
      funcionalidade
    }
  }
`;

export const FIND_ALL_PLANO_TIPO_PAGAMENTO = gql`
  query ($tipoPagamento: TipoPagamentoPlano, $pageableDTO: PageableDTOInput) {
    findAllPlanoTipoPagamento(
      tipoPagamento: $tipoPagamento
      pageableDTO: $pageableDTO
    ) {
      last
      content {
        id
        nome
        ativo
        tipoPagamento {
          id
          descricao
          valor
          modulos {
            funcionalidade
          }
        }
      }
    }
  }
`;

export const FIND_PLANO_BY_ID = gql`
  query ($id: UUID) {
    findPlanoById(id: $id) {
      id
      nome
      ativo
      valor
      tipoPagamento {
        id
        valor
        modulos {
          id
          funcionalidade
        }
        descricao
      }
    }
  }
`;

export const GET_ADICIONAIS = gql`
  query ($ativo: Boolean, $pageableDTO: PageableDTOInput, $search: String) {
    findAllModulo(ativo: $ativo, pageableDTO: $pageableDTO, search: $search) {
      id
      funcionalidade
      periodoCobranca
      tipoCobranca
      valor
      group
      authority {
        nome
      }
    }
  }
`;

export const FIND_BY_MODULO_ID = gql`
  query ($moduloId: UUID) {
    findByModuloId(moduloId: $moduloId) {
      funcionalidade
      group
      id
      periodoCobranca
      tipoCobranca
      valor
      financeiroCompleto
      alterarValor
    }
  }
`;

export const FIND_ALL_MODULO_VENDA = gql`
  query (
    $ativo: Boolean
    $pageableDTO: PageableDTOInput
    $tipoPagamentoDTO: TipoPagamentoDTOInput
  ) {
    findAllModulo(
      ativo: $ativo
      pageableDTO: $pageableDTO
      tipoPagamentoDTO: $tipoPagamentoDTO
    ) {
      id
      funcionalidade
      valor
      periodoCobranca
      tipoCobranca
      financeiroCompleto
      alterarValor
      tipoSecretariaVirtual
    }
  }
`;

export const IMPRIMIR_BOLETO = gql`
  query ($vendaId: UUID, $parcelaIdAzulControle: Long) {
    imprimirBoletoDaVenda(vendaId: $vendaId,parcelaIdAzulControle: $parcelaIdAzulControle)
  }
`;

export const LIST_ESPECIALIDADES = gql`
  query ($search: SearchDTOInput, $pageableDTO: PageableDTOInput) {
    findAllEspecialidadeMedicaList(
      searchDTO: $search
      pageableDTO: $pageableDTO
    ) {
      label: especialidade
      value: id
    }
  }
`;

export const PROFISSIONAIS_SAUDE_BY_VENDA = gql`
  query ($venda: VendaInput, $pageableDTO: PageableDTOInput) {
    findProfissionaisSaudeByVenda(venda: $venda, pageableDTO: $pageableDTO) {
      last
      content {
        tipo
        id
        nome
        email
        cpf
        ativo
        utilizaVIZI
        possuiSenhaCadastrada
        perfilPublico
        usuario {
          id
        }
      }
    }
  }
`;

export const BOLETOS_EMITIDOS_VENDA = gql`
  query ($vendaId: UUID) {
    findPagamentosVenda(vendaId: $vendaId) {
      dataVencimento
      pago
      valor
      valorPago
      parcelaIdAzulControle
    }
  }
`;

export const FIND_ALL_PREFIXOS = gql`
  query {
    findAllPrefixoList {
      prefixo
    }
  }
`;

export const FIND_ALL_PROFISSIONAL_SAUDE_PROFISSAO = gql`
  query ($searchDTO: SearchDTOInput, $pageableDTO: PageableDTOInput) {
    findAllProfissionalSaudeProfissao(
      pageableDTO: $pageableDTO
      searchDTO: $searchDTO
    ) {
      numberOfElements
      totalElements
      last
      pageable {
        pageNumber
        pageSize
      }
      content {
        descricao
        id
      }
    }
  }
`;

export const FIND_ALL_IDIOMA = gql`
  query ($search: String, $pageableDTO: PageableDTOInput, $ativo: Boolean) {
    findAllIdioma(pageableDTO: $pageableDTO, search: $search, ativo: $ativo) {
      numberOfElements
      totalElements
      last
      pageable {
        pageNumber
        pageSize
      }
      content {
        descricao
        id
      }
    }
  }
`;

export const FIND_ALL_FORMA_PAGAMENTO = gql`
  query ($searchDTO: SearchDTOInput, $pageableDTO: PageableDTOInput) {
    findAllFormaPagamento(pageableDTO: $pageableDTO, searchDTO: $searchDTO) {
      numberOfElements
      totalElements
      last
      pageable {
        pageNumber
        pageSize
      }
      content {
        nome
        id
      }
    }
  }
`;

export const FIND_ALL_ESTADOS = gql`
  query ($searchDTO: SearchDTOInput, $pageableDTO: PageableDTOInput) {
    findAllEstado(pageableDTO: $pageableDTO, searchDTO: $searchDTO) {
      numberOfElements
      totalElements
      last
      pageable {
        pageNumber
        pageSize
      }
      content {
        nome
        id
        uf
      }
    }
  }
`;

export const FIND_PERFIL_PUBLICO_BY_VENDA_AND_PROFISSIONAL_SAUDE = gql`
  query ($venda: VendaInput, $profissionalSaudeId: Long) {
    findPerfilPublicoByVendaAndProfissionalSaudeId(
      venda: $venda
      profissionalSaudeId: $profissionalSaudeId
    ) {
      id
      nome
      nomeLinkPublico
      idPublico
      telefonePrincipal
      telefoneSecundario
      email
      sobre

      formacao
      experiencia

      atendePresencial
      utilizaTelemedicina
      atendeCrianca
      permiteBuscaProfissional
      permiteBuscaGoogle
      permiteProfissionalUnidade
      perfilVitrine

      idiomas {
        id
        descricao
      }

      formasPagamento {
        id
        nome
      }

      facebook
      instagram
      linkedin
      twitter

      corPersonalizada
      fotoPerfil
      banner

      horarioAtendimento

      especialidades {
        especialidadeMedica {
          id
          especialidade
        }
        ativo
        valorOnline
        valorPresencial
      }

      unidade {
        id
      }

      dadosIugu {
        verified
      }

      perfilPublicoProfissionais {
        permiteExibicao
        perfilPublicoProfissional {
          id
          nome
          avaliacaoNotaMedia
          atendeCrianca
          especialidades {
            ativo
            especialidadeMedica {
              id
              especialidade
            }
            valorOnline
            valorPresencial
          }
          convenios {
            id
            descricao
          }

          dadosIugu {
            verified
          }
        }
      }

      perfilPublicoUnidades {
        permiteExibicao
        perfilPublicoUnidade {
          id
          nome

          unidade {
            id
            nomeFantasia
            telefonePrincipal
            telefonePrincipalDDI
            endereco {
              cep
              bairro
              complemento
              nomeLogradouro
              numero
              municipio {
                nome
                uf
                estado {
                  nome
                }
              }
            }
            latitude
            longitude
          }

          especialidades {
            ativo
            especialidadeMedica {
              id
              especialidade
            }
            valorOnline
            valorPresencial
          }
        }
      }
    }
  }
`;

export const FIND_PERFIL_PUBLICO_UNIDADE_BY_VENDA_ID = gql`
  query ($vendaId: UUID) {
    findPerfilPublicoUnidadeByVendaId(vendaId: $vendaId) {
      id
      nome
      nomeLinkPublico
      idPublico
      telefonePrincipal
      telefoneSecundario
      email
      sobre

      formacao
      experiencia

      atendePresencial
      utilizaTelemedicina
      atendeCrianca
      permiteBuscaProfissional
      permiteBuscaGoogle
      permiteProfissionalUnidade
      perfilVitrine

      idiomas {
        id
        descricao
      }

      formasPagamento {
        id
        nome
      }

      facebook
      instagram
      linkedin
      twitter

      corPersonalizada
      fotoPerfil
      banner

      horarioAtendimento

      especialidades {
        especialidadeMedica {
          id
          especialidade
        }
        ativo
        valorOnline
        valorPresencial
      }

      unidade {
        id
        rede {
          id
          whitelabel {
            id
            nome
          }
        }
      }

      dadosIugu {
        id
        verified
      }

      perfilPublicoProfissionais {
        permiteExibicao
        perfilPublicoProfissional {
          id
          nome
          avaliacaoNotaMedia
          atendeCrianca
          especialidades {
            ativo
            especialidadeMedica {
              id
              especialidade
            }
            valorOnline
            valorPresencial
          }
          convenios {
            id
            descricao
          }
          fotoPerfil

          dadosIugu {
            verified
          }
        }
      }

      perfilPublicoUnidades {
        permiteExibicao
        perfilPublicoUnidade {
          id
          nome

          unidade {
            id
            nomeFantasia
            telefonePrincipal
            telefonePrincipalDDI
            endereco {
              cep
              bairro
              complemento
              nomeLogradouro
              numero
              municipio {
                nome
                uf
                estado {
                  nome
                }
              }
            }
            latitude
            longitude
          }

          especialidades {
            ativo
            especialidadeMedica {
              id
              especialidade
            }
            valorOnline
            valorPresencial
          }
        }
      }
    }
  }
`;

export const FIND_ALL_TUSS_24 = gql`
  query ($termo: String, $codigoTermo: String, $pageableDTO: PageableDTOInput) {
    findAllTuss24TerminologiaCBO(
      termo: $termo
      codigoTermo: $codigoTermo
      pageableDTO: $pageableDTO
    ) {
      numberOfElements
      totalElements
      last
      pageable {
        pageNumber
        pageSize
      }
      content {
        id
        codigoTermo
        termo
      }
    }
  }
`;

export const LINK_LOGIN_AZUL_CONTROLE_COMERCIAL = gql`
  query {
    linkLoginComercialAzulControle
  }
`;

export const FIND_ALL_BANCOS = gql`
  query($pageableDTO: PageableDTOInput) {
    findAllBanco(pageableDTO: $pageableDTO) {
      content {
        id
        nome
        codigo
      }
      last
    }
  }
`;

export const FIND_ALL_MUNICIPIO = gql`
  query ($searchDTO: SearchDTOInput, $pageableDTO: PageableDTOInput) {
    findAllMunicipio(searchDTO: $searchDTO, pageableDTO: $pageableDTO) {
      content {
        id
        nome
        uf
      }
      last
    }
  }
`;

export const FIND_BY_DADOSBRASPAG_UNIDADE = gql`
  query ($unidade: Long) {
    findByDadosBraspagUnidade(unidade: $unidade) {
      dadoBancario {
        agencia
        banco {
          id
          nome
          codigo
        }
        conta
        digitoAgencia
        digitoVerificador
        operacao
        tipoConta
        documento
      }
      id
      endereco {
        bairro
        cep
        complemento
        estado {
          id
          nome
        }
        municipio {
          id
          nome
        }
        numero
        nomeLogradouro
      }
      nomeFantasia
      email
      documento
      razaoSocial
      responsavelNome
      responsavelTelefone
      status
      documentoTipo
    }
  }
`;

export const FIND_PERFIS_PUBLICO_BY_UNIDADE = `
  query ($unidade: UnidadeInput, $pageableDTO: PageableDTOInput) {
    findPefisPublicosByUnidade(unidade: $unidade, pageableDTO: $pageableDTO) {
      last
      content {
        id
        nome
        ativo
        dadosBraspag {
          id
        }
        dadosIugu {
          id
        }
      }
    }
  }
`;
