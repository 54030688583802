import React, { useReducer, useState } from 'react';
import styled from '@emotion/styled';
import ButtonBox from '../../../../components/button/ButtonBox';
import { buttonsDetalheVenda } from '../../../list-vendas/components/criar-venda/constants';
import { useEffect } from 'react';
import moment from 'moment';
import { canExtender } from '../../../../services/authority-service/can-criar-venda';
import { useLazyQuery, useMutation } from 'react-apollo';
import {
  FINALIZAR_VENDA,
  UPDATE_EXTENDER_PERIODO_GRATIS,
  BLOQUEAR_ACESSO_UNIDADE,
  DESBLOQUEAR_ACESSO_UNIDADE,
  INICIAR_PERIODO_GRATIS,
  DESATIVAR_SECRETARIA_VIRTUAL_UNIDADE,
  ATIVAR_SECRETARIA_VIRTUAL_UNIDADE,
  UPDATE_PLUG_ZAP,
  REATIVAR_VENDA,
} from '../../../../graphql/mutations';
import { useParams } from 'react-router-dom';
import AlertDialog from '../../../../components/AlertDialog/AlertDialog';
import ModalSlider from '../../../../components/modal-slider/modal-slider';
import StepperProfissionalSaude from '../criar-profissional-saude/stepperProfissionalSaude';
import { Financeiro } from '../financeiro/financeiro';
import ModalGrupoPermissao from '../grupo-permissao/ModalGrupoPermissao';
import ModalDesbloquearVenda from '../modal-desbloquear-venda/ModalDesbloquearVenda';
import DefinirPeriodoDiasGratis from '../definir-periodo-dias-gratis';
import Plano from '../../../list-vendas/components/criar-venda/plano';
import { titleStep } from '../sobre-plano/constants';
import { DadosPlano } from '../sobre-plano/dadosPlano';
import Switch from '@material-ui/core/Switch';

import { useHistory } from 'react-router-dom';
import { PERFIL_PUBLICO_UNIDADE_VENDA } from '../../../../router/names';
import { FIND_BY_UNIDADE_CONFIGURACAO_UNIDADE } from '../../../../graphql/queryes';
import { ContentTesteGratis } from './ContentTesteGratis';
import AlertWithInputSwitch from '../../../../components/AlertDialog/AlertWithInputSwitch';

const ButtonsActionVenda = ({
  vendaQuery,
  variables,
  refetch,
  dadosDaVenda,
  setNotification,
  toggleButtonsDialog,
  getDadosRegistradosProfissionais,
  isAuthoritySistema,
  canEdit,
  buttonsDialog,
  handleClickEditarDadosUnidade,
  findProfissionaisSaudeByVenda,
  pageableDTOProfissionais,
  handleEditProfissionalSaude,
}) => {
  const [estenderPeriodoGratisDialog, toggleEstenderPeriodoGratisDialog] =
    useReducer((state) => !state, false);
  const [secretariaVirtualDialog, toggleSecretariaVirtualDialog] = useReducer((state) => !state, false);
  const [loadingExtenderPeriodoGratis, setLoadingExtenderPeriodoGratis] =
    useState(false);
  const [showButtonExtenderPeriodoGratis, setShowButtonExtenderPeriodoGratis] =
    useState(false);
  const [loadingSecretariaVirtual, setLoadingSecretariaVirtual] = useState(false);
  const [
    isOpenCriarProfissionalSaudeModal,
    setIsOpenCriarProfissionalSaudeModal,
  ] = useState(false);
  const [openBloqeuarVendaDialog, setOpenBloquearVendaDialog] = useState(false);
  const [loadingAlertDialog, setLoadingAlertDialog] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [openAlertDialogAtivarVenda, setOpenAlertDialogAtivarVenda] = useState(false);
  const [isOpenGrupoPermissao, setIsOpenGrupoPermissao] = useState(false);
  const [
    isOpenMovimentacoesFinanceirasModal,
    setIsOpenMovimentacoesFinanceirasModal,
  ] = useState(false);
  const [
    isOpenDefinirTesteGratisVendaModal,
    setIsOpenDefinirTesteGratisVendaModal,
  ] = useState(false);
  const [sobrePlanoStep, toggleSobrePlanoStep] = useReducer(
    (state) => !state,
    false
  );
  const [openDesbloquearVendaDialog, setOpenDesbloquearVendaDialog] =
    useState(false);
  const [contentModalFinanceiro, setContentModalFinanceiro] =
    useState('sem pagamento');
  const [novaDataValidade, setNovaDataValidade] = useState(moment());
  const [secretariaConfigurations, setSecretariaConfigurations] = useState({
    tipoSecretariaVirtual: dadosDaVenda?.tipoSecretariaVirtual || null,
    apiInstancia: dadosDaVenda?.unidade?.plugzapiUrl || null,
    tokenSeguranca: dadosDaVenda?.unidade?.plugzapiClientToken || null,
  })
  const [isOpenAlterarPlanoModal, setIsOpenAlterarPlanoModal] = useState(false);
  const [stepSelectedSobrePlano, setStepSelectedSobrePlano] = useState(0);
  const [periodoSelected, setPeriodoSelected] = useState(null);

  const history = useHistory();

  useEffect(() => {
    let dataVencimentoUnidade = moment(
      dadosDaVenda.unidade?.dataVencimento
    ).subtract(3, 'days');
    setShowButtonExtenderPeriodoGratis(moment() > dataVencimentoUnidade);
    setContentModalFinanceiro(
      dadosDaVenda?.meioPagamentoPlataforma ? 'pagamento' : 'semPagamento'
    );
  }, [dadosDaVenda]);

  const [fetchConfigUnidade, { data }] = useLazyQuery(FIND_BY_UNIDADE_CONFIGURACAO_UNIDADE, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (dadosDaVenda?.unidade?.id) {
      fetchConfigUnidade({
        variables: {
          unidadeId: dadosDaVenda?.unidade?.id,
        },
      });
    }
  }, [dadosDaVenda, fetchConfigUnidade])
  useEffect(() => {
    setSecretariaConfigurations(
      (configurations) =>
        ({ ...configurations, tipoSecretariaVirtual: vendaQuery.findByIdVenda.tipoSecretariaVirtual }));
  },
    [vendaQuery])

  const unidadeConfiguracaoUnidade = data?.findByUnidadeConfiguracaoUnidade || {};
  useEffect(() => {
    setSecretariaConfigurations((dataSecretaria) =>
      ({ ...dataSecretaria, status: unidadeConfiguracaoUnidade?.utilizaPlugzapi || false }))
  }, [unidadeConfiguracaoUnidade])

  const [finalizarVenda] = useMutation(FINALIZAR_VENDA);
  const [reativarVenda] = useMutation(REATIVAR_VENDA);
  const [extenderPeriodoGratis] = useMutation(UPDATE_EXTENDER_PERIODO_GRATIS);
  const [iniciarPeriodoGratis] = useMutation(INICIAR_PERIODO_GRATIS);
  const [bloquearAcessoUnidade] = useMutation(BLOQUEAR_ACESSO_UNIDADE);
  const [desbloquearAcessoUnidade] = useMutation(DESBLOQUEAR_ACESSO_UNIDADE);
  const [desativarSecretariaVirtual] = useMutation(DESATIVAR_SECRETARIA_VIRTUAL_UNIDADE);
  const [UpdateSecretariaApi] = useMutation(UPDATE_PLUG_ZAP);
  const [ativarSecretariaVirtual] = useMutation(ATIVAR_SECRETARIA_VIRTUAL_UNIDADE);
  const params = useParams();

  const showButtonAdicionarProfissionalSaude = !!dadosDaVenda.unidade;
  const isStatusFinalizada = 'FINALIZADA' === dadosDaVenda.status;
  const showButtonIniciarTesteGratis =
    'CADASTRO_PARCIAL' === dadosDaVenda.status;
  const unidadeVencida =
    new Date(dadosDaVenda?.unidade?.dataVencimento) < new Date();

  const validaExtenderPeriodoGratis = () => {
    return (
      showButtonExtenderPeriodoGratis &&
      canExtender() &&
      'PERIODO_GRATIS' === dadosDaVenda.status
    );
  };

  const handleClickCriarProfissionalSaude = () => {
    setIsOpenCriarProfissionalSaudeModal(true);
  };

  const handleOpenFinalizarVenda = () => {
    setOpenAlertDialog(true);
  };

  const handleOpenAtivarVenda = () => {
    setOpenAlertDialogAtivarVenda(true);
  };

  const handleClickEditarGrupoPermissao = () => {
    setIsOpenGrupoPermissao(true);
  };

  const handleClickMovimentacoes = () => {
    setIsOpenMovimentacoesFinanceirasModal(true);
  };

  const handleClickSobrePlano = () => {
    setStepSelectedSobrePlano(0);
    toggleSobrePlanoStep();
  };

  const handleOpenDesbloquearVendaDialog = () => {
    setOpenDesbloquearVendaDialog(true);
  };

  const handleOpenBloquearVendaDialog = () => {
    setOpenBloquearVendaDialog(true);
  };

  const handleCloseMovimentacoesFinanceirasModal = () => {
    setIsOpenMovimentacoesFinanceirasModal(false);
    refetch();
  };

  const handleCloseDefinirTesteGratisVenda = () => {
    setIsOpenDefinirTesteGratisVendaModal(false);
    refetch();
  };

  const handleClickCloseGrupoPermissao = () => {
    setIsOpenGrupoPermissao(false);
  };

  const handleChangeNovaDataValidade = (value) => {
    setNovaDataValidade(value);
  };

  const handleClickCloseAlterarPlanoModal = () => {
    setIsOpenAlterarPlanoModal(false);
    refetch();
  };

  const handleClickExtenderPeriodoGratis = async () => {
    if (!periodoSelected?.value) {
      setNotification({
        variant: 'error',
        isOpen: true,
        message: 'Selecione um período.',
      });

      return;
    };
    setLoadingExtenderPeriodoGratis(true);

    await extenderPeriodoGratis({
      variables: {
        vendaId: params.vendaId,
        dias: periodoSelected?.value
      },
    })
      .then(() => {
        buttonsDialog && toggleButtonsDialog();
        setShowButtonExtenderPeriodoGratis(false);
        refetch();
        toggleEstenderPeriodoGratisDialog();
        setNotification({
          variant: 'success',
          isOpen: true,
          message: 'Período gratis estendido com sucesso.',
        });
      })
      .catch((error) => {
        setNotification({
          variant: 'error',
          isOpen: true,
          message: error.message,
        });
      })
      .finally(() => {
        setLoadingExtenderPeriodoGratis(false);
      });
  };

  const handlePeriodo = (periodo) => {
    setPeriodoSelected(periodo);
  };
  const handleChangeInputSecretaria = (e) => {

    const { name, value, checked } = e.target;

    setSecretariaConfigurations((configurations) => ({ ...configurations, [name]: value || checked }));
  }
  const handleClickIniciarPeriodoGratis = async () => {
    if (!periodoSelected?.value) {
      setNotification({
        variant: 'error',
        isOpen: true,
        message: 'Selecione um período.',
      });

      return;
    };

    await iniciarPeriodoGratis({
      variables: {
        vendaId: params.vendaId,
        dias: periodoSelected?.value
      },
    }).then((response) => {
      buttonsDialog && toggleButtonsDialog();
      refetch();
      toggleEstenderPeriodoGratisDialog();
    });
  };

  const handleCloseCriarProfissionalSaudeModal = async () => {
    await getDadosRegistradosProfissionais({
      variables: {
        unidadeId: dadosDaVenda?.unidade?.id,
        unidade: { id: dadosDaVenda?.unidade?.id },
        venda: { id: dadosDaVenda?.id },
      },
    });
    await findProfissionaisSaudeByVenda({
      variables: {
        venda: { id: params.vendaId },
        pageableDTO: {
          ...pageableDTOProfissionais,
        },
      },
    });
    buttonsDialog && toggleButtonsDialog();
    setIsOpenCriarProfissionalSaudeModal(false);
    refetch();
  };

  const handleClickBloquearVenda = async () => {
    try {
      const response = await bloquearAcessoUnidade({
        variables: {
          unidadeId: dadosDaVenda?.unidade?.id,
        },
      });

      if (response.errors) {
        throw Error(
          `Não foi possível bloquear a venda da unidade ${dadosDaVenda?.unidade?.nome}.`
        );
      }

      buttonsDialog && toggleButtonsDialog();
      setOpenBloquearVendaDialog(false);
    } catch (error) {
      setNotification({
        variant: 'error',
        isOpen: false,
        message: error.message,
      });
    } finally {
      refetch();
    }
  };
  const handleDesbloquearVenda = async () => {
    try {
      setLoadingAlertDialog(true);
      const dataVencimento = novaDataValidade
        .set({
          hour: 23,
          minute: 59,
          second: 0,
          millisecond: 0,
        })
        .format('YYYY-MM-DDTHH:mm:ss');
      const response = await desbloquearAcessoUnidade({
        variables: {
          dataVencimento,
          unidadeId: dadosDaVenda?.unidade?.id,
        },
      });

      if (response.errors) {
        throw Error(
          `Não foi possível desbloquear a venda da unidade ${dadosDaVenda?.unidade?.nome}.`
        );
      }
    } catch (error) {
      setNotification({
        variant: 'error',
        isOpen: false,
        message: error.message,
      });
    } finally {
      buttonsDialog && toggleButtonsDialog();
      setLoadingAlertDialog(false);
      setOpenDesbloquearVendaDialog(false);
      setNovaDataValidade(moment());
      refetch();
    }
  };

  const handleClickFinalizarVenda = async () => {
    try {
      setLoadingAlertDialog(true);
      await finalizarVenda({
        variables: {
          vendaId: variables?.id,
        },
      });
      buttonsDialog && toggleButtonsDialog();
      refetch();
      setLoadingAlertDialog(false);
      setOpenAlertDialog(false);
    } catch ({ graphQLErrors }) {
      if (graphQLErrors) {
        setNotification({
          variant: 'error',
          isOpen: true,
          message: graphQLErrors[0].message,
        });
      }
      setLoadingAlertDialog(false);
    }
  };

  const handleClickAtivarVenda = async () => {
    try {
      setLoadingAlertDialog(true);
      await reativarVenda({
        variables: {
          vendaId: dadosDaVenda?.id,
        },
      });
      buttonsDialog && toggleButtonsDialog();
      refetch();
      setOpenAlertDialogAtivarVenda(false);
      setLoadingAlertDialog(false);
    } catch ({ graphQLErrors }) {
      if (graphQLErrors) {
        setNotification({
          variant: 'error',
          isOpen: true,
          message: graphQLErrors[0].message,
        });
      }
      setLoadingAlertDialog(false);
    }
  }

  const handleOpenPerfilPublico = () => {
    const venda = vendaQuery.findByIdVenda;
    const href = PERFIL_PUBLICO_UNIDADE_VENDA.replace(':vendaId', venda.id);

    history.push(href);
  };

  const handleClickSecretariaVirtualNotPlugzapi = async () => {
    try {
      setLoadingSecretariaVirtual(true);
      if (unidadeConfiguracaoUnidade.utilizaWaba) {
        await desativarSecretariaVirtual({
          variables: {
            unidadeId: dadosDaVenda?.unidade?.id
          }
        })
        await fetchConfigUnidade({
          variables: {
            unidadeId: dadosDaVenda?.unidade?.id,
          },
        });
      } else {
        await ativarSecretariaVirtual({
          variables: {
            unidadeId: dadosDaVenda?.unidade?.id
          }
        })
        await fetchConfigUnidade({
          variables: {
            unidadeId: dadosDaVenda?.unidade?.id,
          },
        });
      }
      toggleSecretariaVirtualDialog();
    } catch (error) {
      setNotification({
        variant: 'error',
        isOpen: true,
        message: `Não foi possível ${unidadeConfiguracaoUnidade.utilizaWaba ? 'Inativar' : 'Ativar'} a secretaria virtual.`,
      })
    } finally {
      setLoadingSecretariaVirtual(false);
    }
  }
  const handleClickSecretariaVirtualUsePlugzapi = async () => {
    try {
      setLoadingSecretariaVirtual(true);
      await UpdateSecretariaApi({
        variables: {
          unidade: {
            id: dadosDaVenda?.unidade?.id
          },
          utilizaPlugzapi: secretariaConfigurations.status,
          plugzapiUrl: secretariaConfigurations.apiInstancia,
          PlugzapiClientToken: secretariaConfigurations.tokenSeguranca
        }
      })
      toggleSecretariaVirtualDialog();
    } catch {
      setNotification({
        variant: 'error',
        isOpen: true,
        message: `Não foi possível ${unidadeConfiguracaoUnidade.utilizaPlugzapi &&
          !!dadosDaVenda?.unidade?.plugzapiUrl ? 'Inativar' : 'Ativar'} a secretaria virtual.`,
      })
    }
    finally {
      setLoadingSecretariaVirtual(false);
    }
  }


  const modalInputs = [{
    id: "apiInstancia",
    name: "apiInstancia",
    label: "API da instância",
    onChange: handleChangeInputSecretaria,
    value: secretariaConfigurations.apiInstancia,
    disabled: !unidadeConfiguracaoUnidade?.utilizaPlugzapi
  },
  {
    id: "tokenSeguranca",
    name: "tokenSeguranca",
    label: "Token de segurança",
    onChange: handleChangeInputSecretaria,
    value: secretariaConfigurations.tokenSeguranca,
    disabled: !unidadeConfiguracaoUnidade?.utilizaPlugzapi
  }]

  return (
    <>
      <ButtonsContainer buttonsDialog={buttonsDialog}>
        {buttonsDetalheVenda({
          functions: {
            handleClickCriarProfissionalSaude,
            toggleEstenderPeriodoGratisDialog,
            handleOpenFinalizarVenda,
            handleOpenAtivarVenda,
            handleClickEditarGrupoPermissao,
            handleClickMovimentacoes,
            handleClickSobrePlano,
            handleOpenDesbloquearVendaDialog,
            handleOpenBloquearVendaDialog,
            handleClickEditarDadosUnidade,
            handleOpenPerfilPublico,
            toggleSecretariaVirtualDialog,
          },
          showButtonAdicionarProfissionalSaude,
          isAuthoritySistema,
          isStatusFinalizada,
          showButtonIniciarTesteGratis,
          validaExtenderPeriodoGratis,
          unidadeVencida,
          canEdit,
          tipoSecretariaVirtual: secretariaConfigurations.tipoSecretariaVirtual
        }).map((button, index) => {
          const { bg, color, onClick, icon, title, hidden, isSwitch, switchChecked } = button;
          return (
            !hidden && (
              <ButtonBox bg={bg} onClick={onClick} key={index}>
                <ContentButton>
                  <RowButton color={color}>{icon}
                    {isSwitch && <Switch checked={!!switchChecked} color="primary" />}
                  </RowButton>
                  <ButtonTitle color={color}> {title} </ButtonTitle>
                </ContentButton>
              </ButtonBox>
            )
          );
        })}
      </ButtonsContainer>
      {isOpenCriarProfissionalSaudeModal && (
        <ModalSlider
          isOpen={isOpenCriarProfissionalSaudeModal}
          onClose={() => setIsOpenCriarProfissionalSaudeModal(false)}
          title="Adicionar Profissional de saúde"
        >
          <StepperProfissionalSaude
            onClose={handleCloseCriarProfissionalSaudeModal}
            dados={dadosDaVenda}
            criandoProfissional={isOpenCriarProfissionalSaudeModal}
            handleEditProfissionalSaude={handleEditProfissionalSaude}
            setNotification={setNotification}
          />
        </ModalSlider>
      )}
      {estenderPeriodoGratisDialog && (
        <AlertDialog
          open={estenderPeriodoGratisDialog}
          alertTitle="Período Grátis"
          alertDescription={<ContentTesteGratis
            title={showButtonIniciarTesteGratis
              ? 'Deseja iniciar o teste gratuito?'
              : 'Estender Período Grátis?'}
            handlePeriodo={handlePeriodo}
          />}
          onCancel={toggleEstenderPeriodoGratisDialog}
          onOk={
            showButtonIniciarTesteGratis
              ? handleClickIniciarPeriodoGratis
              : handleClickExtenderPeriodoGratis
          }
          loadingOk={loadingExtenderPeriodoGratis}
        />
      )}
      <AlertDialog
        open={openBloqeuarVendaDialog}
        alertTitle={'Deseja bloquear a venda?'}
        onCancel={() => setOpenBloquearVendaDialog(false)}
        onOk={handleClickBloquearVenda}
        loadingOk={loadingAlertDialog}
      />

      <ModalSlider
        isOpen={isOpenMovimentacoesFinanceirasModal}
        onClose={handleCloseMovimentacoesFinanceirasModal}
        style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
        title="Financeiro"
        goBack={
          contentModalFinanceiro === 'alterarPagamento' &&
          (() => setContentModalFinanceiro('pagamento'))
        }
      >
        {isOpenMovimentacoesFinanceirasModal && (
          <Financeiro
            dados={dadosDaVenda}
            onClose={handleCloseMovimentacoesFinanceirasModal}
            setNotification={setNotification}
            contentModalFinanceiro={contentModalFinanceiro}
            setContentModalFinanceiro={setContentModalFinanceiro}
          />
        )}
      </ModalSlider>
      {isOpenGrupoPermissao && (
        <ModalGrupoPermissao
          open={isOpenGrupoPermissao}
          onClose={handleClickCloseGrupoPermissao}
          idRede={vendaQuery.findByIdVenda.unidade?.rede?.id}
        />
      )}
      <ModalDesbloquearVenda
        open={openDesbloquearVendaDialog}
        onClose={() => setOpenDesbloquearVendaDialog(false)}
        onChangeDate={handleChangeNovaDataValidade}
        onSave={handleDesbloquearVenda}
        loadingSave={loadingAlertDialog}
        date={novaDataValidade}
      />

      <ModalSlider
        isOpen={isOpenDefinirTesteGratisVendaModal}
        onClose={handleCloseDefinirTesteGratisVenda}
        title="Mudar período grátis"
      >
        {isOpenDefinirTesteGratisVendaModal && (
          <DefinirPeriodoDiasGratis
            onClose={handleCloseDefinirTesteGratisVenda}
            dados={dadosDaVenda}
          />
        )}
      </ModalSlider>

      <ModalSlider
        isOpen={isOpenAlterarPlanoModal}
        onClose={handleClickCloseAlterarPlanoModal}
        title={!!dadosDaVenda.id && 'Alterar Plano'}
      >
        {isOpenAlterarPlanoModal && (
          <Plano
            onClose={handleClickCloseAlterarPlanoModal}
            dados={dadosDaVenda.plano}
            vendaId={dadosDaVenda.id}
            tipoCobrancaSelecionada={dadosDaVenda?.tipoPagamento?.descricao}
          />
        )}
      </ModalSlider>

      <ModalSlider
        isOpen={sobrePlanoStep}
        onClose={toggleSobrePlanoStep}
        title={titleStep[stepSelectedSobrePlano]}
        goBack={
          stepSelectedSobrePlano !== 0 && (() => setStepSelectedSobrePlano(0))
        }
      >
        {sobrePlanoStep && (
          <DadosPlano
            venda={dadosDaVenda}
            stepSelectedSobrePlano={stepSelectedSobrePlano}
            setStepSelectedSobrePlano={setStepSelectedSobrePlano}
            setNotification={setNotification}
            toggleSobrePlanoStep={toggleSobrePlanoStep}
            carregaVenda={refetch}
          />
        )}
      </ModalSlider>

      <AlertDialog
        open={openAlertDialog}
        alertTitle={'Deseja cancelar a venda?'}
        alertDescription={
          'Por favor, certifique-se este processo não pode ser desfeito'
        }
        onCancel={() => setOpenAlertDialog(false)}
        onOk={handleClickFinalizarVenda}
        loadingOk={loadingAlertDialog}
      />

      <AlertDialog
        open={openAlertDialogAtivarVenda}
        alertTitle={'Deseja ativar a venda?'}
        onCancel={() => setOpenAlertDialogAtivarVenda(false)}
        onOk={handleClickAtivarVenda}
        loadingOk={loadingAlertDialog}
      />

      {secretariaConfigurations.tipoSecretariaVirtual === "PLUGZAP" && <AlertWithInputSwitch
        open={secretariaVirtualDialog}
        alertTitle={` Secretária Virtual - ${secretariaConfigurations.tipoSecretariaVirtual === "PLUGZAP" ? "Plugzapi" : "Oficial"}`}
        alertDescription={`Deseja atualizar o status da secretaria virtual?`}
        onCancel={toggleSecretariaVirtualDialog}
        onOk={handleClickSecretariaVirtualUsePlugzapi}
        loadingOk={loadingSecretariaVirtual}
        handleSwitchChange={handleChangeInputSecretaria}
        switchLabel={secretariaConfigurations.status ? "Ativar" : "Inativar"}
        switchValue={secretariaConfigurations.status}
        arrayInputs={modalInputs}
        buttonOkLabel={"Salvar"}
      />}


      {secretariaConfigurations.tipoSecretariaVirtual === "API_OFICIAL" &&
        <AlertDialog
          open={secretariaVirtualDialog}
          alertTitle={`${unidadeConfiguracaoUnidade.utilizaWaba ? 'Inativar' : 'Ativar'} Secretária Virtual`}
          alertDescription={`Deseja ${unidadeConfiguracaoUnidade.utilizaWaba ? 'inativar' : 'ativar'} a secretaria virtual?`}
          onCancel={toggleSecretariaVirtualDialog}
          onOk={handleClickSecretariaVirtualNotPlugzapi}
          loadingOk={loadingSecretariaVirtual}
        />

      }
    </>
  );
};

export default ButtonsActionVenda;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  background-color: #fff;
  padding: 16px;
  box-shadow: 0 2px 4px -2px rgba(30, 60, 90, 0.1);
  z-index: 100;
  gap: 15px;
  border-radius: 8px;
  margin: 24px 0 16px;
  @media (max-width: 760px) {
    ${({ buttonsDialog }) =>
    buttonsDialog
      ? 'display: flex; flex-direction: column;'
      : 'display: none;'}
  }
`;

const ContentButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ButtonTitle = styled.label`
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.color || '#333'};
  text-align: start;
  cursor: pointer;
`;

const RowButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${(props) => props.color || '#333'};
  max-height: 24px !important;

  > span {
    margin-top: -8px;
  }
`;
