import React from 'react';

import styled from '@emotion/styled';
import { Switch } from '@material-ui/core';
import { TextField } from '../../../../components/TextField';
import { usePerfilPublicoContext } from '../../perfil-publico-context';
import FormControl from '../../../../components/FormControl';
import { SocialNetworkBuildUrls } from '../../../../utils/socialNetworkBuildUrls';
import Textarea from '../../../../components/Textarea';
import { useNestor } from '../../../../hooks/useNestor';
import InputFile from '../../../../components/InputFile';
import { nestorService } from '../../../../services/nestor';
import PerfilPublicoColorPicker from './PerfilPublicoColorPicker';

export const PerfilPublicoCustomizacao = () => {
  const [showColorPicker, setShowColorPicker] = React.useState(false);
  const [colorPickerAnchorEl, setColorPickerAnchorEl] = React.useState(null);

  const { setField, values, errors, perfilPublico, unidade } =
    usePerfilPublicoContext();
  const [uploadFotoPerfil, { isLoading }] = useNestor();
  const [uploadBanner, { isLoading: isLoadingBanner }] = useNestor();
  const { venda } = usePerfilPublicoContext();
  const defaultColor = venda?.unidade?.rede?.whitelabel?.corPrincipal;

  const handleSetRedesSociais = (name, value, network) => {
    const valueWithoutUrl = value.replace(
      SocialNetworkBuildUrls.networksUrls[network],
      ''
    );

    setField(
      'redesSociais.' + name,
      SocialNetworkBuildUrls.buildUrl(network, valueWithoutUrl)
    );
  };

  const handleSetFotoPerfil = async (file) => {
    const id = await uploadFotoPerfil(file);
    setField('fotoPerfilId', id);
  };

  const handleSetBanner = async (file) => {
    const id = await uploadBanner(file);
    setField('bannerId', id);
  };

  const redesSociaisValues = values.redesSociais || {};
  const redesSociaisErrors = errors.redesSociais || {};

  const buildFotoPerfilUrl = () => {
    const isFotoPerfilSaved =
      perfilPublico?.fotoPerfil &&
      values?.fotoPerfilId === perfilPublico?.fotoPerfil;

    if (isFotoPerfilSaved) {
      return nestorService.url(perfilPublico.fotoPerfil);
    }

    if (values?.fotoPerfilId) {
      return nestorService.urlTemp(values.fotoPerfilId);
    }
  };

  const buildBannerUrl = () => {
    const isBannerSaved =
      perfilPublico?.banner && values?.bannerId === perfilPublico?.banner;

    if (isBannerSaved) {
      return nestorService.url(perfilPublico.banner);
    }

    if (values?.bannerId) {
      return nestorService.urlTemp(values.bannerId);
    }
  };

  const handleChangeColor = (color) => {
    setField('color', color);
    setShowColorPicker(false);
  };

  const fotoPerfilUrl = buildFotoPerfilUrl();
  const bannerUrl = buildBannerUrl();

  const color = values.color;

  return (
    <Container>
      <ContainerColumn>
        {unidade ? (
          <Card>
            <div>
              <div>
                <h2>Medidas de segurança</h2>
                <p
                  style={{
                    fontSize: 12,
                    margin: '6px 0px 0px 0px',
                  }}
                >
                  Informe os seus pacientes as possíveis medidas de segurança
                  utilizadas. Exemplo: Uso de máscaras, álcool em gel, etc...
                </p>
              </div>
            </div>

            <Textarea
              error={errors?.medidasSeguranca}
              value={values.medidasSeguranca}
              onChange={(e) => setField('medidasSeguranca', e.target.value)}
            />
          </Card>
        ) : (
          <Card>
            <h2>Horário de atendimento</h2>

            <Textarea
              error={errors?.horarioAtendimento}
              value={values.horarioAtendimento}
              onChange={(e) => setField('horarioAtendimento', e.target.value)}
            />
          </Card>
        )}

        <Card>
          <h2>Redes sociais</h2>
          <div>Para cada rede social digite apenas o nome de usuário</div>

          <FormControl error={redesSociaisErrors?.facebook}>
            <TextField
              label="Facebook"
              value={redesSociaisValues.facebook}
              onChange={(e) =>
                handleSetRedesSociais(
                  'facebook',
                  e.target.value,
                  SocialNetworkBuildUrls.networks.FACEBOOK
                )
              }
            />
          </FormControl>

          <FormControl error={redesSociaisErrors?.instagram}>
            <TextField
              label="Instagram"
              value={redesSociaisValues.instagram}
              onChange={(e) =>
                handleSetRedesSociais(
                  'instagram',
                  e.target.value,
                  SocialNetworkBuildUrls.networks.INSTAGRAM
                )
              }
            />
          </FormControl>

          <FormControl error={redesSociaisErrors?.linkedin}>
            <TextField
              label="Linkedin"
              value={redesSociaisValues.linkedin}
              onChange={(e) =>
                handleSetRedesSociais(
                  'linkedin',
                  e.target.value,
                  SocialNetworkBuildUrls.networks.LINKEDIN
                )
              }
            />
          </FormControl>

          <FormControl error={redesSociaisErrors?.twitter}>
            <TextField
              label="Twitter"
              value={redesSociaisValues.twitter}
              onChange={(e) =>
                handleSetRedesSociais(
                  'twitter',
                  e.target.value,
                  SocialNetworkBuildUrls.networks.TWITTER
                )
              }
            />
          </FormControl>
        </Card>
      </ContainerColumn>

      <ContainerColumn>
        <Card>
          <h2>Customização do perfil</h2>

          <div>
            <div>Personalizar cor do perfil</div>

            <InputColor
              onClick={(e) => {
                setShowColorPicker(true);
                setColorPickerAnchorEl(e.currentTarget);
              }}
            >
              {color || defaultColor}

              <InputColorIcon color={color || defaultColor} />
            </InputColor>

            <PerfilPublicoColorPicker
              open={showColorPicker}
              anchorEl={colorPickerAnchorEl}
              placement={'top'}
              handleChange={handleChangeColor}
              color={color}
              defaultColor={defaultColor}
              onClose={() => setShowColorPicker(false)}
            />
          </div>
        </Card>

        <Card>
          <h2>Imagem Principal de Perfil</h2>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {fotoPerfilUrl && (
              <img
                src={fotoPerfilUrl}
                alt="foto"
                style={{
                  width: '200px',
                  height: '200px',
                  borderRadius: '50%',
                  objectFit: 'contain',
                }}
              />
            )}
          </div>

          <InputFile
            onChange={(e) => handleSetFotoPerfil(e.target.files[0])}
            accept="image/png, image/jpeg"
            disabled={isLoading}
          >
            ADICIONAR IMAGEM
          </InputFile>
        </Card>

        <Card>
          <h2>Banner do Perfil</h2>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {bannerUrl && (
              <img
                src={bannerUrl}
                alt="foto"
                style={{
                  width: '100%',
                  height: '200px',
                  objectFit: 'cover',
                }}
              />
            )}
          </div>

          <InputFile
            onChange={(e) => handleSetBanner(e.target.files[0])}
            accept="image/png, image/jpeg"
            disabled={isLoadingBanner}
          >
            ADICIONAR IMAGEM
          </InputFile>
        </Card>
      </ContainerColumn>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-gap: 24px;
  padding-bottom: 20px;
  margin-left: 0px;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 760px) {
    grid-template-columns: 1fr;
  }
`;

const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
`;

const InputColor = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background-color: #f5f5f5;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  width: 100px;
  cursor: pointer;
  margin-top: 16px;
`;

const InputColorIcon = styled.div`
  width: 14px;
  height: 14px;
  background-color: ${(props) => props.color};
  border-radius: 4px;
  border: 1px solid #e0e0e0;
`;
