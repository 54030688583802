import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Button from '../button';
import { css } from '@emotion/core';

export const Tabs = ({value, onChange, children, disabled}) => {
  const isTabElement = (child) => {
    return child.displayName === 'Tab';
  }

  return(
      <StyledTabs>
        {React.Children.map(children, (child) => {
          if(!React.isValidElement(child) || isTabElement(child)) return child;
          return React.cloneElement(child, {
            active: child.props.value == value,
            onClick: (e) => onChange(child.props.value, e),
            disabled: disabled
          })
        })}
      </StyledTabs>
  )
}

Tabs.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

const StyledTabs = styled.div`
  display: flex;
  align-items: center;
  border-radius: 100px;
  overflow: hidden;

  background: #ECECEC;

  padding: 2px;
  `

export const Tab = styled(Button)`
  border-width: 0px;
  height: 36px;

  ${props => props.flex && `flex: 1;`}

  ${props => props.active ?
  css`
      background-color: #fff;
      box-shadow: 0px 1.00259px 2.00519px rgba(0, 0, 0, 0.16);
      color: #000;

      &:hover, &:focus {
        background-color: #fff;
      }` :

  css`
      background-color: transparent;
      color: #BDBDBD;
      box-shadow: none;

      &:hover, &:focus {
        background-color: rgba(0, 0, 0, 0.025);
      }
  `}
`
