import React, { useEffect, useReducer, useState } from 'react';
import styled from '@emotion/styled';
import { useQuery, useLazyQuery, useMutation } from 'react-apollo';
import Colors from '../../theme/Colors';
import { FIND_BY_ID_PROFISSIONAL_SAUDE_VENDA, FIND_BY_ID_VENDA, GET_DADOS_REGISTRADOS_PROFISSIONAIS, FIND_BY_UNIDADE_CONFIGURACAO_UNIDADE, PROFISSIONAIS_SAUDE_BY_VENDA, FIND_ALL_TIPO_PROFISSIONAL_SAUDE_LIST } from '../../graphql/queryes';
import { useParams } from 'react-router-dom';
import ModalSlider from '../../components/modal-slider/modal-slider';
import SimpleModal from '../../components/simple-modal/simple-modal';
import StepperProfissionalSaude from './components/criar-profissional-saude/stepperProfissionalSaude';
import MeioDeCobranca from './components/meio-de-cobranca';
import EditarUnidade from './components/editar-unidade';
import { withStyles } from "@material-ui/core/styles";
import Notification from '../../components/notification/Notification';
import MeioDeCobrancaBoleto from "./components/meio-de-cobranca-boleto";
import { checkUserRole } from '../../utils/checkUserRole';
import ModalDetalhesProfissionalSaude from './components/modal-detalhes-profissional-saude/modal-detalhes-profissional-saude';
import { CardDados, CardDado } from './components/card';
import { dadosRegistradosCard, dadosVendaCard, dadosVendedorCard, pageableDTOProfissionaisDefault, profissionaisCadastradosCard } from './constants';
import { DadosUnidade } from './components/dados-unidade';
import ProfissionaisSaude from './components/ProfissionaisSaude';
import EditarDadosResponsavel from './components/editar-dados-responsavel';
import EditarDadosEmpresarial from './components/editar-dados-empresarial';
import EditarEndereco from './components/editar-endereco';
import EditarEspecialidades from './components/editar-especialidades';
import getVendaStatusColors from '../../utils/getVendaStatusColors';
import string from '../../utils/string';
import BaseLayout, { BaseLayoutHeader } from '../../layout/base-layout';
import Header, { HeaderProfile, HeaderTitle } from '../../layout/components/header';
import { FilterIcon } from '../../components/icons/Filter';
import ButtonsActionVenda from './components/buttons-action-venda/ButtonsActionVenda';
import ModalQuantidadeTipoProfissional from './components/modal-quantidade-tipo-profissional/ModalQuantidadeTipoProfissional';
import ModalDesbloquearVenda from './components/modal-desbloquear-venda/ModalDesbloquearVenda';
import moment from 'moment';
import { DESBLOQUEAR_ACESSO_UNIDADE, UPDATE_VALOR_TOTAL_VENDA } from '../../graphql/mutations';
import ModalValorRecorrencia from './components/modal-valor-recorrente/ModalValorRecorrencia';
import Number from '../../utils/number';

const VisualizarVenda = ({ classes }) => {

  const notificationDefault = {
    variant: '',
    message: '',
    isOpen: false,
  };
  const params = useParams();

  const { data: vendaQuery, loading, refetch, error, variables } = useQuery(FIND_BY_ID_VENDA, {
    variables: {
      id: params.vendaId,
    },
  });


  const dadosDaVenda = vendaQuery?.findByIdVenda || {};

  const [
    findProfissionaisSaudeByVenda,
    { data: profissionais, loading: loadingProfissionaisSaudeVenda },
  ] = useLazyQuery(PROFISSIONAIS_SAUDE_BY_VENDA, {
    fetchPolicy: 'no-cache',
  });

  const [desbloquearAcessoUnidade] = useMutation(DESBLOQUEAR_ACESSO_UNIDADE);
  const [updateValorTotalVenda]= useMutation(UPDATE_VALOR_TOTAL_VENDA)

  const { data: tiposProfissionalSaude } = useQuery(
    FIND_ALL_TIPO_PROFISSIONAL_SAUDE_LIST
  );

  const [isOpenMeioDeCobrancaModal, setIsOpenMeioDeCobrancaModal] = useState(false);
  const [isOpenEditarDadosUnidadeModal, setIsOpenEditarDadosUnidadeModal] = useState(false);
  const [isOpenEditarDadosProfissinalSaudeModal, setIsOpenEditarDadosProfissinalSaudeModal] = useState(false);
  const [isOpenEditarContratante, setIsOpenEditarContratante] = useState(false);
  const [openDesbloquearVendaDialog, setOpenDesbloquearVendaDialog] = useState(false);
  const [notification, setNotification] = useState({
    ...notificationDefault,
  });
  const [isOpenPagamentoBoletoDialog, setIsOpenPagamentoBoletoDialog] = useState(false);
  const [isAuthoritySistema, setIsAuthoritySistema] = useState(false);
  const [isProfissionalModalOpen, setIsProfessionalModalOpen] = useState(false);
  const [loadingAlertDialog, setLoadingAlertDialog] = useState(false);
  const [selectedProfissional, setSelectedProfissional] = useState(null);
  const [pageableDTOProfissionais, setPageableDTOProfissionais] = useState(pageableDTOProfissionaisDefault);

  const [buttonsDialog, toggleButtonsDialog] = useReducer((state) => !state, false);
  const [editDadosEmpresarial, toggleEditDadosEmpresarial] = useReducer((state) => !state, false);
  const [editEndereco, toggleEditEndereco] = useReducer((state) => !state, false);
  const [editEspecialidades, toggleEditEspecialidades] = useReducer((state) => !state, false);
  const [quantidadeProfissionaisModal, toggleQuantidadeProfissionaisModal] = useReducer((state) => !state, false);
  const [novaDataValidade, setNovaDataValidade] = useState(moment());

  const [profissionalSelected, setProfissionalSelected] = useState(null);
  const [canEdit, setCanEdit] = useState(false);
  const [openModalValorRecorrencia, setOpenModalEditValorRecorrencia] = useState(false);
  const [valorRecorrencia, setValorRecorrencia] = useState(0);
  const [checkBox, setCheckBox] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  useEffect(()=>{
    const {valorTotalComDesconto}=dadosDaVenda;
    const valor = valorTotalComDesconto
    setValorRecorrencia(valor)
    setCheckBox(dadosDaVenda.calcularVenda)
  },[dadosDaVenda])

  const verificaAuthority = async () => {
    const isAuthoritySistema = await checkUserRole("ROLE_SISTEMA");
    const isAuthorityComercial = await checkUserRole("ROLE_COMERCIAL");


    setIsAuthoritySistema(isAuthoritySistema);
    setCanEdit(isAuthoritySistema || (isAuthorityComercial && dadosDaVenda.status !== 'ATIVA'));
  }

  const handleCloseMeioDeCobrancaModal = () => {
    setIsOpenMeioDeCobrancaModal(false);
    refetch();

  };

  const handleClickEditarUnidade = () => {
    buttonsDialog && toggleButtonsDialog();
    setIsOpenEditarDadosUnidadeModal(true);
  };

  const handleCloseEditarUnidade = () => {
    setIsOpenEditarDadosUnidadeModal(false);
    refetch();
  };

  const handleClickEditarContratante = () => {
    setIsOpenEditarContratante(true);
  };

  const handleCloseEditarContratante = () => {
    setIsOpenEditarContratante(false);
    refetch();
  };

  const handleCloseEditDadosEmpresarial = () => {
    toggleEditDadosEmpresarial();
    refetch();
  }

  const handleCloseEditEndereco = () => {
    toggleEditEndereco();
    refetch();
  }

  const handleCloseEditEspecialidades = () => {
    toggleEditEspecialidades();
    refetch();
  }

  const handleCloseEditQuantidadeTipoProfissional = () => {
    toggleQuantidadeProfissionaisModal();
    refetch();
  }

  const handleCloseEditarProfissionalSaude = () => {
    setIsOpenEditarDadosProfissinalSaudeModal(false);
    refetch();
    findProfissionaisSaudeByVenda({
      variables: {
        venda: { id: params.vendaId },
        pageableDTO: {
          ...pageableDTOProfissionais,
        },
      },
    });
  };

  const handleClosePagamentoBoleto = () => {
    setIsOpenPagamentoBoletoDialog(false);
    refetch();
  }

  const handleClickEditarDadosUnidade = () => {
    buttonsDialog && toggleButtonsDialog();
    setIsOpenEditarDadosUnidadeModal(true);
  }

  const [getDadosRegistradosProfissionais, { data: dadosRegistradosProfissionais }] = useLazyQuery(GET_DADOS_REGISTRADOS_PROFISSIONAIS, {
    fetchPolicy: 'no-cache',
  })

  const [fetchConfigUnidade, { data }] = useLazyQuery(FIND_BY_UNIDADE_CONFIGURACAO_UNIDADE, {
    fetchPolicy: 'no-cache',
  })
  const [
    findByIdProfissionalSaudeDaVenda,
    { data: getProfissionalSaude, loading: loadingProfissionalSaude }
  ] = useLazyQuery(FIND_BY_ID_PROFISSIONAL_SAUDE_VENDA, {
    fetchPolicy: 'no-cache',
    onCompleted: () => { handleFormataDadosProfissional(getProfissionalSaude?.findByIdProfissionalSaudeDaVenda) }
  })

  const handleEditProfissionalSaude = (profissional) => {
    findByIdProfissionalSaudeDaVenda({
      variables: {
        vendaId: params.vendaId,
        id: profissional.id
      }
    });

    setIsOpenEditarDadosProfissinalSaudeModal(true);
  };

  const handleOnCloseInfoModal = () => {
    setIsProfessionalModalOpen(false);
    setSelectedProfissional(null);
  }

  const handleFormataDadosProfissional = (profissional) => {
    const tipo = tiposProfissionalSaude.findAllTipoProfissionalSaudeList.find(item => item.value === profissional.tipo)
    const grupoPermissoes = profissional.unidadeProfissionalSaudeGroups.map(item => {
      return { ...item.group }
    })

    setProfissionalSelected({
      ...profissional,
      grupoPermissoes,
      tipo
    });
  }

  useEffect(() => {
    if (dadosDaVenda?.unidade?.id) {
      fetchConfigUnidade({
        variables: {
          unidadeId: dadosDaVenda?.unidade?.id,
        },
      });
      getDadosRegistradosProfissionais({
        variables: {
          unidadeId: dadosDaVenda?.unidade?.id,
          unidade: { id: dadosDaVenda?.unidade?.id },
          venda: { id: dadosDaVenda?.id },
        }
      })
    }
  }, [dadosDaVenda?.unidade?.id])

  useEffect(() => {
    dadosDaVenda?.id && verificaAuthority();
  }, [dadosDaVenda]);

  if (loading) {
    return (
      <BaseLayout>
        <Content>
          <HeaderDesbloquearVenda>
            <HeaderDesbloquearVendaTitle>Carregando...</HeaderDesbloquearVendaTitle>
          </HeaderDesbloquearVenda>
        </Content>
      </BaseLayout>
    );
  }


  if (error) {
    return (
      <BaseLayout>
        <Content>
          <HeaderDesbloquearVenda>
            <HeaderDesbloquearVendaTitle>Essa venda pertence a outra plataforma.</HeaderDesbloquearVendaTitle>
          </HeaderDesbloquearVenda>
        </Content>
      </BaseLayout>
    );
  }

  const getStatusVenda = () => {
    const status = dadosDaVenda.status === "FINALIZADA" ? "CANCELADA" : dadosDaVenda.status;

    return <ContentStatusVenda>
      Status
      <StatusVenda status={dadosDaVenda.status}>{
        string.capitalize(status?.toLowerCase().replace('_', ' ') || '')
      } </StatusVenda>
    </ContentStatusVenda>

  }

  const handleClickQuantidadeProfissionais = () => {
    toggleQuantidadeProfissionaisModal();
  }

  const handleOpenModalDataVencimento = () => {
    setNovaDataValidade(moment(dadosDaVenda?.unidade?.dataVencimento));
    setOpenDesbloquearVendaDialog(true);
  }

  const handleOpenModalEditValorRecorrente = () => {
    setOpenModalEditValorRecorrencia(true);
  }

  const closeModalEditValorRecorrencia=()=>{
    setOpenModalEditValorRecorrencia(false);
    setValorRecorrencia(dadosDaVenda.valorTotalComDesconto);
    setCheckBox(dadosDaVenda.calcularVenda)
  }
  
  const handleSalvarValorRecorrencia = async () => {
    setLoadingSave(true);
    const valor = Number.currencyToFloat(valorRecorrencia);
    const variables = {
      variables: {
        vendaId: dadosDaVenda.id,
        valorVenda: valor,
        calcularVenda: checkBox,
      },
    }
    try {
      await updateValorTotalVenda(variables);
      closeModalEditValorRecorrencia();
    } catch (error) {
      throw new Error(error);
    } finally {
      setLoadingSave(false);
      refetch();
    }
  }
  const handleChangeNovoValorRecorrencia = (e) => {
    setValorRecorrencia(e.target.value)
  }
  const handlePermiteEditarValor = () => {
    setCheckBox(!checkBox)
  }

  const handleChangeNovaDataValidade = (value) => {
    setNovaDataValidade(value);
  }

  const handleDesbloquearVenda = async () => {
    try {
      setLoadingAlertDialog(true);
      const dataVencimento = novaDataValidade
        .set({
          hour: 23,
          minute: 59,
          second: 0,
          millisecond: 0,
        })
        .format('YYYY-MM-DDTHH:mm:ss');
      const response = await desbloquearAcessoUnidade({
        variables: {
          dataVencimento,
          unidadeId: dadosDaVenda?.unidade?.id,
        },
      });

      if (response.errors) {
        throw Error(
          `Não foi possível desbloquear a venda da unidade ${dadosDaVenda?.unidade?.nome}.`
        );
      }
    } catch (error) {
      setNotification({
        variant: 'error',
        isOpen: false,
        message: error.message,
      });
    } finally {
      buttonsDialog && toggleButtonsDialog();
      setLoadingAlertDialog(false);
      setOpenDesbloquearVendaDialog(false);
      setNovaDataValidade(moment());
      refetch();
    }
  };

  return (
    <BaseLayout>
      <BaseLayoutHeader>
        <Header>
          <HeaderTitle />
          <FilterProfile>
            <ButtonMobile onClick={toggleButtonsDialog}>
              <FilterIcon />
            </ButtonMobile>
            <HeaderProfile />
          </FilterProfile>
        </Header>
      </BaseLayoutHeader>
      <Container>
        <ButtonsActionVenda
          vendaQuery={vendaQuery}
          variables={variables}
          refetch={refetch}
          dadosDaVenda={dadosDaVenda}
          setNotification={setNotification}
          toggleButtonsDialog={toggleButtonsDialog}
          buttonsDialog={buttonsDialog}
          getDadosRegistradosProfissionais={getDadosRegistradosProfissionais}
          isAuthoritySistema={isAuthoritySistema}
          canEdit={canEdit}
          handleClickEditarDadosUnidade={handleClickEditarDadosUnidade}
          findProfissionaisSaudeByVenda={findProfissionaisSaudeByVenda}
          pageableDTOProfissionais={pageableDTOProfissionais}
          handleEditProfissionalSaude={handleEditProfissionalSaude}
        />
        <Content>
          <RowCardDados gridTemplateColumns="68% 30%">
            <CardDados title={'Dados da venda'} infoTitle={getStatusVenda()}>
              <ContentCardDados direction="row">
                {dadosVendaCard({
                  dados: dadosDaVenda,
                  canEdit: canEdit,
                  functions: {
                    handleOpenModalDataVencimento,
                    handleOpenModalEditValorRecorrente,
                  }
                }).map(({ title, value, children, handleEdit, canEdit }) => {
                  return (
                    <CardDado
                      key={title}
                      title={title}
                      value={value}
                      handleEdit={handleEdit}
                      canEdit={canEdit}
                    >
                      {children}
                    </CardDado>
                  );
                })}
              </ContentCardDados>
            </CardDados>
            <CardDados title={'Dados do vendedor'}>
              <ContentCardDados direction="column">
                {dadosVendedorCard({ dados: dadosDaVenda }).map((item) => {
                  const { title, value, children } = item;
                  return (
                    <CardDado key={title} title={title} value={value}>
                      {children}
                    </CardDado>
                  );
                })}
              </ContentCardDados>
            </CardDados>
          </RowCardDados>
          <CardDados title={'Dados do contratante'}>
            <DadosUnidade
              dados={dadosDaVenda}
              canEdit={canEdit}
              functions={{
                handleClickEditarUnidade,
                handleClickEditarContratante,
                toggleEditDadosEmpresarial,
                toggleEditEndereco,
                toggleEditEspecialidades,
              }}
              isAuthoritySistema={isAuthoritySistema}
            />
          </CardDados>
          <RowCardDados>
            <CardDados
              title={'Profissionais cadastrados'}
              handleEdit={handleClickQuantidadeProfissionais}
              canEdit={canEdit}
            >
              <ContentCardDados direction="row" gr>
                {profissionaisCadastradosCard({
                  dados:
                    dadosRegistradosProfissionais?.findQuantidadeProfissionaisSaudeByVenda,
                  quantidadeTipoProfissional:
                    dadosDaVenda.vendaQuantidadeTipoProfissionalSaude,
                }).map((item) => {
                  const { title, value } = item;
                  return <CardDado key={title} title={title} value={value} />;
                })}
              </ContentCardDados>
            </CardDados>

            <CardDados title={'Dados registrados'}>
              <ContentCardDados direction="row">
                {dadosRegistradosCard({
                  dados: dadosRegistradosProfissionais,
                  venda: dadosDaVenda,
                }).map((item) => {
                  const { title, value, children } = item;
                  return (
                    <CardDado key={title} title={title} value={value}>
                      {children}
                    </CardDado>
                  );
                })}
              </ContentCardDados>
            </CardDados>
          </RowCardDados>
          <ProfissionaisSaude
            dadosDaVenda={dadosDaVenda}
            setIsOpenEditarDadosProfissinalSaudeModal={
              setIsOpenEditarDadosProfissinalSaudeModal
            }
            setPageableDTO={setPageableDTOProfissionais}
            pageableDTO={pageableDTOProfissionais}
            findProfissionaisSaudeByVenda={findProfissionaisSaudeByVenda}
            profissionais={profissionais}
            handleEditProfissionalSaude={handleEditProfissionalSaude}
            loadingProfissionaisSaudeVenda={loadingProfissionaisSaudeVenda}
          />

          <ModalSlider
            isOpen={isOpenEditarDadosUnidadeModal}
            onClose={handleCloseEditarUnidade}
            title="Alterar Unidade"
          >
            {isOpenEditarDadosUnidadeModal && (
              <EditarUnidade
                onClose={handleCloseEditarUnidade}
                venda={dadosDaVenda}
              />
            )}
          </ModalSlider>

          <ModalSlider
            isOpen={isOpenEditarContratante}
            onClose={handleCloseEditarContratante}
            title="Dados do responsável"
          >
            {isOpenEditarContratante && (
              <EditarDadosResponsavel
                onClose={handleCloseEditarContratante}
                venda={dadosDaVenda}
              />
            )}
          </ModalSlider>

          <ModalSlider
            isOpen={editDadosEmpresarial}
            onClose={toggleEditDadosEmpresarial}
            title="Dados empresarial"
          >
            {editDadosEmpresarial && (
              <EditarDadosEmpresarial
                onClose={handleCloseEditDadosEmpresarial}
                venda={dadosDaVenda}
              />
            )}
          </ModalSlider>

          <ModalSlider
            isOpen={editEndereco}
            onClose={toggleEditEndereco}
            title="Endereço"
          >
            {editEndereco && (
              <EditarEndereco
                onClose={handleCloseEditEndereco}
                venda={dadosDaVenda}
              />
            )}
          </ModalSlider>

          <ModalSlider
            isOpen={editEspecialidades}
            onClose={toggleEditEspecialidades}
            title="Especialidades"
          >
            {editEspecialidades && (
              <EditarEspecialidades
                onClose={handleCloseEditEspecialidades}
                venda={dadosDaVenda}
              />
            )}
          </ModalSlider>

          {!loadingProfissionalSaude && (
            <ModalSlider
              isOpen={isOpenEditarDadosProfissinalSaudeModal}
              onClose={handleCloseEditarProfissionalSaude}
              title="Editar Profissional de Saúde"
            >
              {isOpenEditarDadosProfissinalSaudeModal && (
                <StepperProfissionalSaude
                  dados={{
                    profissional: profissionalSelected,
                    unidade: dadosDaVenda.unidade,
                    vendaId: dadosDaVenda?.id,
                  }}
                  onClose={handleCloseEditarProfissionalSaude}
                  setNotification={setNotification}
                  disabled={!canEdit}
                />
              )}
            </ModalSlider>
          )}

          <ModalSlider
            isOpen={isOpenPagamentoBoletoDialog}
            onClose={handleClosePagamentoBoleto}
            title="Boleto"
          >
            {isOpenPagamentoBoletoDialog && (
              <MeioDeCobrancaBoleto
                onClose={handleClosePagamentoBoleto}
                venda={dadosDaVenda}
              />
            )}
          </ModalSlider>

          <ModalSlider
            isOpen={isOpenMeioDeCobrancaModal}
            onClose={handleCloseMeioDeCobrancaModal}
            title="Adicionar Meio de Cobrança"
          >
            {isOpenMeioDeCobrancaModal && (
              <MeioDeCobranca
                onClose={handleCloseMeioDeCobrancaModal}
                venda={dadosDaVenda}
              />
            )}
          </ModalSlider>

          <SimpleModal
            open={isProfissionalModalOpen}
            handleOnClose={handleOnCloseInfoModal}
          >
            <ModalDetalhesProfissionalSaude
              profissionalDeSaude={selectedProfissional}
              onClose={handleOnCloseInfoModal}
            />
          </SimpleModal>

          <SimpleModal
            open={isProfissionalModalOpen}
            handleOnClose={handleOnCloseInfoModal}
          >
            <ModalDetalhesProfissionalSaude
              profissionalDeSaude={selectedProfissional}
              onClose={handleOnCloseInfoModal}
            />
          </SimpleModal>

          <Notification
            close={() => {
              setNotification({
                ...notificationDefault,
              });
            }}
            reset={() => {
              setNotification({
                ...notificationDefault,
              });
            }}
            isOpen={notification.isOpen}
            variant={notification.variant}
            message={notification.message}
          />
          {quantidadeProfissionaisModal && (
            <ModalSlider
              isOpen={quantidadeProfissionaisModal}
              onClose={toggleQuantidadeProfissionaisModal}
              title="Editar quantidade de profissionais"
            >
              {quantidadeProfissionaisModal && (
                <ModalQuantidadeTipoProfissional
                  onClose={handleCloseEditQuantidadeTipoProfissional}
                  dados={{
                    ...dadosDaVenda,
                    profissionaisQuantidade:
                      dadosDaVenda.vendaQuantidadeTipoProfissionalSaude,
                  }}
                  setNotification={setNotification}
                />
              )}
            </ModalSlider>
          )}
          {isOpenEditarDadosUnidadeModal}
          {buttonsDialog && (
            <SimpleModal
              open={buttonsDialog}
              handleOnClose={toggleButtonsDialog}
            >
              <ButtonsActionVenda
                vendaQuery={vendaQuery}
                variables={variables}
                refetch={refetch}
                dadosDaVenda={dadosDaVenda}
                setNotification={setNotification}
                toggleButtonsDialog={toggleButtonsDialog}
                getDadosRegistradosProfissionais={
                  getDadosRegistradosProfissionais
                }
                isAuthoritySistema={isAuthoritySistema}
                canEdit={canEdit}
                buttonsDialog={buttonsDialog}
                handleClickEditarDadosUnidade={handleClickEditarDadosUnidade}
                findProfissionaisSaudeByVenda={findProfissionaisSaudeByVenda}
                pageableDTOProfissionais={pageableDTOProfissionais}
                handleEditProfissionalSaude={handleEditProfissionalSaude}
              />
            </SimpleModal>
          )}
        </Content>
        <ModalDesbloquearVenda
          open={openDesbloquearVendaDialog}
          onClose={() => setOpenDesbloquearVendaDialog(false)}
          onChangeDate={handleChangeNovaDataValidade}
          onSave={handleDesbloquearVenda}
          loadingSave={loadingAlertDialog}
          date={novaDataValidade}
        />
       { openModalValorRecorrencia && 
       <ModalValorRecorrencia
        dados={valorRecorrencia}
        open={openModalValorRecorrencia}
        onClose={closeModalEditValorRecorrencia}
        onChange={handleChangeNovoValorRecorrencia}
        onSave={handleSalvarValorRecorrencia}
        handlePermiteEditarValor={handlePermiteEditarValor}
        checkBox={checkBox}
        loadingSave={loadingSave}
        />}
      </Container>
    </BaseLayout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 3% 0 2.3%;
  background-color: #f5f5f5;
  height: calc(100% - 80px);
  @media(max-width: 760px) {
    height: calc(100% - 136px);
  }
`

const Content = styled.div`
  width: 100%;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 16px;

`;


const HeaderDesbloquearVenda = styled.div`
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderDesbloquearVendaTitle = styled.div`
  font-size: 18px;
  text-align: left;
  color: ${Colors.primary.main};
  margin-top: 4px;
`;

const Button = styled.button`
  border: 0;
  background-color: #707c97;;
  color: #fff;
  padding: 10px 15px;
  box-sizing: border-box;
  min-height: 34px;
  min-width: 150px;
  border-radius: 30px;
  margin: 10px;
  cursor: pointer;
  font-weight: bold;
  flex-shrink: 0;
`;


const RowCardDados = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns || '1fr 1fr'};

  @media(max-width: 760px) {
    display: flex;
    flex-direction: column;
  }
`;

const ContentCardDados = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 16px;

  @media(max-width: 760px) {
    &> div {
      width: 100%;
    }
  }
`;

const StatusVenda = styled.div`
  text-align: center;
  border-radius: 8px;
  padding: 4px;
  font-weight: 400;
  font-size: 12px;
  ${props => `
    background-color: ${getVendaStatusColors(props.status).backgroundColor};
    border: 1px solid ${getVendaStatusColors(props.status).color};
  `}
`;

const ContentStatusVenda = styled.div`
  font-size: 16px;
  font-weight: 600;
  display: flex;
  gap: 4px;
  align-items: center;
`;

const ButtonMobile = styled.button`
  border-radius: 100px;
  border: 1px solid #F2F2F2;
  width: 40px;
  height: 40px;
  cursor: pointer;
  @media(min-width: 760px) {
    display: none;
  }
`;

const FilterProfile = styled.div`
  display: flex;
  gap: 4px;
`;

export default withStyles(() => ({
  editIcon: {
    color: 'white',
    backgroundColor: Colors.primary.main,
    fontSize: '12px',
    minWidth: 25,
    minHeight: 25,
    width: 25,
    height: 25,
  }
}))(VisualizarVenda);
