import gql from 'graphql-tag';

export const NOVA_UNIDADE = gql`
  mutation NovaUnidade($nome: String, $cnpj: String) {
    createUnidade(unidade: { nome: $nome, cnpj: $cnpj }) {
      id
    }
  }
`;

export const CRIAR_VENDA = gql`
  mutation (
    $planoId: UUID
    $contratante: ContratanteInput
    $utilizaAzulControle: Boolean
  ) {
    createVenda(
      contratante: $contratante
      planoId: $planoId
      utilizaAzulControle: $utilizaAzulControle
    ) {
      id
    }
  }
`;

export const ALTERAR_VENDA = gql`
  mutation ($venda: VendaInput) {
    updateVenda(venda: $venda) {
      id
      plano {
        id
      }
    }
  }
`;

export const FINALIZAR_VENDA = gql`
  mutation ($vendaId: UUID) {
    finalizarVenda(vendaId: $vendaId) {
      id
    }
  }
`;

export const REATIVAR_VENDA = gql`
  mutation ($vendaId: UUID) {
    reativarVenda(vendaId: $vendaId) {
      id
    }
  }
`;

export const CRIAR_VENDA_SEM_CARTAO = gql`
  mutation CriarVendaSemCartao(
    $unidadeNome: String
    $diasPeriodoGratis: Long
    $quantidadeProfissionaisSaudeComAgenda: BigInteger
    $planoId: UUID
  ) {
    createVenda(
      unidadeNome: $unidadeNome
      diasPeriodoGratis: $diasPeriodoGratis
      quantidadeProfissionaisSaudeComAgenda: $quantidadeProfissionaisSaudeComAgenda
      planoId: $planoId
    ) {
      id
    }
  }
`;

export const CRIAR_UNIDADE = gql`
  mutation CriarUnidade($venda: VendaInput, $unidade: UnidadeInput) {
    createUnidade(venda: $venda, unidade: $unidade) {
      id
    }
  }
`;

export const ADICIONAR_CARTAO_CREDITO = gql`
  mutation AdicionarCartaoCredito(
    $vendaId: UUID
    $cartaoCredito: CartaoCreditoInput
  ) {
    adicionarCartaoCreditoAVenda(
      vendaId: $vendaId
      cartaoCredito: $cartaoCredito
    ) {
      id
    }
  }
`;

export const ADICIONAR_BOLETO = gql`
  mutation AdicionarBoleto($vendaId: UUID, $quantidadeParcelas: Long) {
    adicionarBoletoAVenda(
      vendaId: $vendaId
      quantidadeParcelas: $quantidadeParcelas
    ) {
      id
    }
  }
`;

export const ADICIONAR_CARTAO_CREDITO_VENDA_CLIENTE_PAGAMENTO = gql`
  mutation adicionarCartaoCreditoAVendaClientePagamento(
    $vendaId: UUID
    $cartaoCredito: CartaoCreditoInput
  ) {
    adicionarCartaoCreditoAVendaClientePagamento(
      vendaId: $vendaId
      cartaoCredito: $cartaoCredito
    ) {
      id
    }
  }
`;

export const CRIAR_PROFISSIONAL_SAUDE = gql`
  mutation CriarProfissionalSaude(
    $profissionalSaude: ProfissionalSaudeInput
    $venda: VendaInput
    $gruposPermissoes: [GroupInput]
  ) {
    createProfissionalSaude(
      profissionalSaude: $profissionalSaude
      venda: $venda
      gruposPermissoes: $gruposPermissoes
    ) {
      id
    }
  }
`;

export const UPDATE_ENTIDADE_FISICA = gql`
  mutation updateEntidadeFisica($entidadeFisicaInput: EntidadeFisicaInput) {
    updateEntidadeFisica(entidadeFisica: $entidadeFisicaInput) {
      id
    }
  }
`;

export const CREATE_ENTIDADE_FISICA_HAWK = gql`
  mutation createEntidadeFisicaHawk(
    $entidadeFisicaInput: EntidadeFisicaInput!
  ) {
    createEntidadeFisicaHawk(entidadeFisica: $entidadeFisicaInput) {
      id
    }
  }
`;

export const CREATE_ENTIDADE_JURIDICA_HAWK = gql`
  mutation createEntidadeJuridicaHawk(
    $entidadeJuridicaInput: EntidadeJuridicaInput
  ) {
    createEntidadeJuridicaHawk(entidadeJuridica: $entidadeJuridicaInput) {
      id
    }
  }
`;

export const CREATE_ENTIDADE_FISICA_PUBLIC = gql`
  mutation createEntidadeFisica(
    $entidadeFisicaInput: EntidadeFisicaInput!
    $codigo: String
  ) {
    createEntidadeFisica(
      entidadeFisica: $entidadeFisicaInput
      codigo: $codigo
    ) {
      id
    }
  }
`;

export const CREATE_ENTIDADE_JURIDICA_PUBLIC = gql`
  mutation createEntidadeJuridica(
    $entidadeJuridicaInput: EntidadeJuridicaInput!
    $codigo: String
  ) {
    createEntidadeJuridica(
      entidadeJuridica: $entidadeJuridicaInput
      codigo: $codigo
    ) {
      id
    }
  }
`;

export const UPDATE_ENTIDADE_JURIDICA = gql`
  mutation updateEntidadeJuridica(
    $entidadeJuridicaInput: EntidadeJuridicaInput
  ) {
    updateEntidadeJuridica(entidadeJuridica: $entidadeJuridicaInput) {
      id
    }
  }
`;

export const UPDATE_ENTIDADE_STATUS = gql`
  mutation alterarEntidadeAtivo($entidade: EntidadeInput, $ativo: Boolean!) {
    alterarEntidadeAtivo(entidade: $entidade, ativo: $ativo)
  }
`;

export const INICIAR_PERIODO_GRATIS = gql`
  mutation ($vendaId: UUID, $dias: Long) {
    iniciarTesteGratisVenda(vendaId: $vendaId, dias: $dias) {
      id
    }
  }
`;

export const DEFINIR_TESTE_GRATIS_VENDA = gql`
  mutation ($vendaId: UUID, $dias: Long) {
    definirTesteGratisVenda(vendaId: $vendaId, dias: $dias) {
      id
      diasPeriodoGratis
    }
  }
`;

export const UPDATE_UNIDADE = gql`
  mutation ($unidade: UnidadeInput) {
    updateUnidade(unidade: $unidade) {
      id
    }
  }
`;

export const UPDATE_PROFISSIONAL_SAUDE = gql`
  mutation ($profissionalSaude: ProfissionalSaudeInput, $vendaId: UUID) {
    updateProfissionalSaude(
      profissionalSaude: $profissionalSaude
      vendaId: $vendaId
    ) {
      id
    }
  }
`;

export const UPDATE_PROFISSIONAL_SAUDE_GRUPO_PERMISSAO = gql`
  mutation (
    $profissionalSaudeId: Long
    $unidadeId: Long
    $groups: [GroupInput]
  ) {
    updateProfissionalSaudeGroups(
      profissionalSaudeId: $profissionalSaudeId
      unidadeId: $unidadeId
      groups: $groups
    ) {
      id
    }
  }
`;

export const UPDATE_VENDA_PLANO = gql`
  mutation ($venda: VendaInput, $tipoPagemento: TipoPagamentoInput) {
    updateVendaPlanoByTipoPagemento(
      venda: $venda
      tipoPagemento: $tipoPagemento
    )
  }
`;

export const UPDATE_EXTENDER_PERIODO_GRATIS = gql`
  mutation ($vendaId: UUID, $dias: Long) {
    extenderTesteGratisVenda(vendaId: $vendaId, dias: $dias) {
      id
    }
  }
`;

export const UPDATE_EXTENDER_DATA_VENCIMENTO = gql`
  mutation ($vendaId: UUID) {
    extenderDataVencimentoVenda(vendaId: $vendaId) {
      id
    }
  }
`;

export const GERAR_SENHA_PROFISSIONAL_SAUDE = gql`
  mutation ($id: Long) {
    gerarSenhaProfissionalSaude(id: $id)
  }
`;

export const CREATE_GROUP_PERMISSAO = gql`
  mutation ($group: GroupInput) {
    createGroup(group: $group) {
      id
    }
  }
`;

export const UPDATE_GROUP_PERMISSAO = gql`
  mutation ($group: GroupInput) {
    updateGroup(group: $group) {
      id
    }
  }
`;

export const UPDATE_REDE = gql`
  mutation ($rede: RedeInput) {
    updateRede(rede: $rede) {
      id
    }
  }
`;

export const UPDATE_VALOR_TOTAL_VENDA = gql`
  mutation ($vendaId: UUID, $valorVenda: BigDecimal, $calcularVenda: Boolean) {
    updateValorTotalVenda(vendaId: $vendaId, valorVenda: $valorVenda, calcularVenda: $calcularVenda) {
      id
    }
  }
`;

export const ATIVAR_SECRETARIA_VIRTUAL_UNIDADE = gql`
  mutation ($unidadeId: Long) {
    ativarSecretariaVirtualUnidade(unidadeId: $unidadeId)
  }
`;
export const DESATIVAR_SECRETARIA_VIRTUAL_UNIDADE = gql`
  mutation ($unidadeId: Long) {
    desativarSecretariaVirtualUnidade(unidadeId: $unidadeId)
  }
`;

export const UPDATE_PLUG_ZAP = gql`
 mutation (
 $utilizaPlugzapi: Boolean,
 $unidade: UnidadeInput,
 $plugzapiUrl: String,
 $PlugzapiClientToken: String) {
    updatePlugZap(
    unidade: $unidade,
    utilizaPlugzapi: $utilizaPlugzapi,
    plugzapiUrl: $plugzapiUrl,
    PlugzapiClientToken: $PlugzapiClientToken  ){
    id
  }
}
`;

export const BLOQUEAR_ACESSO_UNIDADE = gql`
  mutation ($unidadeId: Long) {
    bloquearAcessoUnidade(unidadeId: $unidadeId) {
      id
    }
  }
`;

export const DESBLOQUEAR_ACESSO_UNIDADE = gql`
  mutation ($dataVencimento: LocalDateTime, $unidadeId: Long) {
    desbloquearAcessoUnidade(
      dataVencimento: $dataVencimento
      unidadeId: $unidadeId
    ) {
      id
    }
  }
`;

export const CREATE_PLANO = gql`
  mutation ($plano: PlanoInput) {
    createPlano(plano: $plano) {
      id
    }
  }
`;

export const UPDATE_PLANO = gql`
  mutation ($plano: PlanoInput) {
    updatePlano(plano: $plano) {
      id
    }
  }
`;

export const CREATE_MODULO = gql`
  mutation ($modulo: ModuloInput) {
    createModulo(modulo: $modulo) {
      id
    }
  }
`;

export const UPDATE_MODULO = gql`
  mutation ($modulo: ModuloInput) {
    updateModulo(modulo: $modulo) {
      id
    }
  }
`;

export const GERAR_BOLETO = gql`
  mutation (
    $comercialNotificacaoRequestDTO: ComercialNotificacaoRequestDTOInput
  ) {
    adicionarBoletoAVenda(
      comercialNotificacaoRequestDTO: $comercialNotificacaoRequestDTO
    ) {
      id
    }
  }
`;

export const CREATE_VENDA = gql`
  mutation (
    $vendaQuantidadeTipoProfissionalSaude: [VendaQuantidadeTipoProfissionalSaudeInput]
    $modulos: [ModuloInput]
    $contratante: ContratanteInput
    $planoTipoPagamentoId: UUID
  ) {
    createVenda(
      vendaQuantidadeTipoProfissionalSaude: $vendaQuantidadeTipoProfissionalSaude
      modulos: $modulos
      contratante: $contratante
      planoTipoPagamentoId: $planoTipoPagamentoId
    ) {
      id
    }
  }
`;

export const ALTERAR_DADOS_RESPONSAVEL = gql`
  mutation (
    $venda: VendaInput
    $documento: String
    $nome: String
    $cnpj: String
    $cargo: String
    $pais: PaisInput
  ) {
    updateDadosResponsavel(
      venda: $venda
      documento: $documento
      nome: $nome
      cnpj: $cnpj
      cargo: $cargo
      pais: $pais
    ) {
      id
    }
  }
`;

export const ALTERAR_DADOS_EMPRESARIAL = gql`
  mutation (
    $venda: VendaInput
    $telefone: String
    $codigoCnes: String
    $inscricaoEstadual: String
    $nomeFantasia: String
    $razaoSocial: String
    $email: String
  ) {
    updateDadosEmpresariais(
      venda: $venda
      telefone: $telefone
      codigoCnes: $codigoCnes
      inscricaoEstadual: $inscricaoEstadual
      nomeFantasia: $nomeFantasia
      razaoSocial: $razaoSocial
      email: $email
    ) {
      id
    }
  }
`;

export const ALTERAR_ENDERECO_VENDA = gql`
  mutation (
    $venda: VendaInput
    $nomeLogradouro: String
    $complemento: String
    $numero: String
    $municipio: MunicipioInput
    $bairro: String
    $cep: String
  ) {
    updateEndereco(
      venda: $venda
      nomeLogradouro: $nomeLogradouro
      complemento: $complemento
      numero: $numero
      municipio: $municipio
      bairro: $bairro
      cep: $cep
    ) {
      id
    }
  }
`;

export const ALTERAR_ESPECIALIDADES = gql`
  mutation (
    $venda: VendaInput
    $especialidades: [EspecialidadeMedicaInput]
    $utilizaAzulControle: Boolean
  ) {
    updateEspecialidades(
      venda: $venda
      especialidades: $especialidades
      utilizaAzulControle: $utilizaAzulControle
    ) {
      id
    }
  }
`;

export const ALTERAR_ADICIONAIS = gql`
  mutation ($venda: VendaInput, $modulos: [ModuloInput]) {
    adicionarModulos(venda: $venda, modulos: $modulos) {
      id
    }
  }
`;

export const ALTERAR_QUANTIDADE_TIPO_PROFISSIONAL_SAUDE = gql`
  mutation (
    $vendaQuantidadeTipoProfissionalSaude: [VendaQuantidadeTipoProfissionalSaudeInput]
    $venda: VendaInput
  ) {
    updateVendaQuantidadeProfissionais(
      vendaQuantidadeTipoProfissionalSaude: $vendaQuantidadeTipoProfissionalSaude
      venda: $venda
    ) {
      id
    }
  }
`;

export const UPDATE_PERFIL_PUBLICO = gql`
  mutation ($perfilPublico: PerfilPublicoInput) {
    updatePerfilPublico(perfilPublico: $perfilPublico) {
      id
    }
  }
`;

export const CREATE_PERFIL_PUBLICO_BY_VENDA_AND_PROFISSIONAL_SAUDE = gql`
  mutation (
    $perfilPublico: PerfilPublicoInput
    $venda: VendaInput
    $profissionalSaudeId: Long
  ) {
    createPerfilPublicoByVendaAndProfissionalSaudeId(
      perfilPublico: $perfilPublico
      venda: $venda
      profissionalSaudeId: $profissionalSaudeId
    ) {
      id
    }
  }
`;

export const CREATE_PERFIL_PUBLICO_UNIDADE_BY_VENDA = gql`
  mutation ($perfilPublico: PerfilPublicoInput, $venda: VendaInput) {
    createPerfilPublicoUnidadeByVenda(
      perfilPublico: $perfilPublico
      venda: $venda
    ) {
      id
    }
  }
`;

export const UPDATE_PERFIL_PUBLICO_FOTO_PERFIL = gql`
  mutation ($perfilPublicoId: UUID, $fotoPerfilId: UUID) {
    updatePerfilPublicoFotoPerfil(
      perfilPublicoId: $perfilPublicoId
      fotoPerfilId: $fotoPerfilId
    ) {
      id
    }
  }
`;

export const UPDATE_PERFIL_PUBLICO_FOTO_BANNER = gql`
  mutation ($perfilPublicoId: UUID, $fotoBannerId: UUID) {
    updatePerfilPublicoFotoBanner(
      perfilPublicoId: $perfilPublicoId
      fotoBannerId: $fotoBannerId
    ) {
      id
    }
  }
`;
export const APLICAR_DESCONTO_VENDA = gql`
  mutation ($vendaId: UUID, $desconto: DescontoInput) {
    aplicarDesconto(vendaId: $vendaId, desconto: $desconto)
  }
`;

export const CREATE_DADOS_RECEBIMENTO = gql`
  mutation ($perfilPublicoId: UUID, $dadosRecebimento: DadosBraspagInput) {
    createDadosBraspagUnidadeSaudeComercial(perfilPublicoId: $perfilPublicoId, dadosBraspag: $dadosRecebimento) {
      id
    }
  }
`;

export const UPDATE_DADOS_RECEBIMENTO = gql`
  mutation ($dadosRecebimento: DadosBraspagInput) {
    updateDadosBraspagComercial(dadosBraspag: $dadosRecebimento) {
      id
    }
  }
`;

export const SET_DADOS_IUGU_BY_PROFISSIONAIS_SAUDE = `
  mutation ($perfisPublicos: [PerfilPublicoInput], $unidade: UnidadeInput) {
    setDadosIuguByProfissionaisSaude(perfisPublicos: $perfisPublicos, unidade: $unidade)
  }
`;
